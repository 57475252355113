/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// newsletter confirmed
export default {
  blog: {
    title:
      'Erfahre mehr über die <span class="has-marker">spannende</span> Zukunft der Inhaltserstellung',
  },
  hero: {
    intro:
      'Du hast dich erfolgreich für unseren Newsletter angemeldet. Wir melden uns bald mit Analysen, Updates und Recherchen zur Zukunft der Inhaltskreation.',
    title: 'Anmeldung bestätigt',
  },
  seo: {
    description:
      'Du hast dich erfolgreich für unseren Newsletter angemeldet. Wir melden bald mit Analysen, Updates und Recherchen zur Zukunft der Inhaltskreation.',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Anmeldung bestätigt - Storyliner',
  },
};
