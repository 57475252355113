/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// about
export default {
  brandStory:
    '<p>Seit Jahrtausenden kommunizieren wir in Wort, Bild und Schrift miteinander. Vieles hat sich dabei stetig gewandelt: das Medium, der Ort oder die Art und Weise. Doch die Umbrüche der vergangenen Jahrzehnte und insbesondere der letzten Dekade haben dazu geführt, dass sich auch der Konsum rasant verändert hat. Jeder von uns konsumiert mehr und mehr Botschaften in immer kürzerer Zeit.<p><p>Dieser Wandel führt bei Unternehmen und Organisationen – die selbst Inhalte erstellen müssen – zu massiven Herausforderungen: denn wie Inhalte produziert werden, ist unverändert geblieben – der Kreativprozess fängt heute noch auf dem leeren Blatt Papier an.</p><p>Wo Inhalte unter Zeitdruck entstehen, leidet die Qualität. Schlechte Qualität führt zu weniger Aufmerksamkeit und Überzeugungskraft. Weniger Überzeugungskraft zu weniger Vertrauen. Vertrauen ist bekanntlich die Basis unserer wirtschaftlichen Existenz.</p><p>Hinter Storyliner steht ein interdisziplinäres Team, das Kreativität und Technologie vereint. Wir glauben an gute Inhalte und an diejenigen, die sie erzählen. Wir sind Designer und Developer, Techies, Werbetexter und Daten-Enthusiasten. Unsere Mission ist es jede Person und Marke mit Technologie zu befähigen überzeugende Inhalte erstellen zu können.</p><blockquote>Wir glauben daran, dass die Zukunft der Künstlichen Intelligenz den Menschen unterstützt – die Kreativität fördert und Fähigkeiten erweitert. Deshalb entwickeln wir Storyliner.</blockquote>',
  hero: {
    title:
      'Wir befähigen Marken <span class="has-marker">über­zeugende Inhalte</span> zu kreieren.',
  },
  jobs: {
    noJobs: 'Aktuell haben wir alle Stellen besetzt.',
    text: 'Hinter Storyliner steht das Hamburger Unternehmen Storyliner Labs. Auf Grundlage jahrelanger Erfahrung im Produktmanagement, Entwicklung, Marketing und Design kannst du bei uns jede Disziplin der Produktentwicklung hautnah miterleben und gestalten. Unser engagiertes Team bietet dir flexible Arbeitszeiten und -modelle, Weiterbildungsmöglichkeiten und Raum für deine Ideen.',
    title:
      '<span class="has-marker">Hilf uns</span> dabei die menschliche Kreativität durch Technologie zu erweitern.',
  },
  partners: {
    dividerBottom: 'divider-bottom-allports-2',
    dividerBottomDark: 'divider-bottom-tawnyport-1',
    logos: [
      {
        alt: 'Logo CONTENTshift',
        src: 'storyliner/logos/logo-contentshift_v1zxr7',
      },
      {
        alt: 'Logo KI Bundesverband',
        src: 'storyliner/logos/logo-ki-bundesverband_kvosfx',
      },
      {
        alt: 'Logo next media accelerator',
        src: 'storyliner/logos/logo-nma_cse7qo',
      },
      {
        alt: 'Logo AWS Activate',
        src: 'storyliner/logos/logo-aws-activate_k6cvnb',
      },
    ],
    title: 'Unsere Partner',
  },
  seo: {
    description:
      'Hinter Storyliner steht ein interdisziplinäres Team, das Kreativität und Technologie vereint. Mehr erfahren …',
    image: `${storylinerRootDomain}/img/meta/storyliner-team.jpg`,
    title: 'Über uns - Storyliner',
  },
  team: {
    image: {
      alt: 'Team Storyliner',
      src: 'storyliner/team/storyliner-team_h9vsjc',
    },
  },
};
