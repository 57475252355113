// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import smoothscroll from 'smoothscroll-polyfill';

// SmoothScrollProvider component
const SmoothScrollProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({
  children,
}) => {
  // handle data changes
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      // init smoothscroll polyfill
      smoothscroll.polyfill();
    }
  }, []);

  // init render
  return <>{children}</>;
};

SmoothScrollProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SmoothScrollProvider;
