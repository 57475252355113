/* eslint-disable import/no-anonymous-default-export */

// import types
import { RouteEnum } from '../../../types';

// import config
import { storylinerRootDomain } from '../../../../config';

// help center
export default {
  hero: {
    intro:
      'Hast du Fragen zum Produkt? Oder brauchst Unterstützung mit deiner Content-Strategie? Unser Help-Center bietet einen ersten Startpunkt für deine Fragen.',
    title: 'Wir helfen <span class="has-marker">gerne</span> weiter.',
  },
  possibilities: {
    items: [
      {
        description:
          'Schildere uns dein Problem per E-Mail, und wir werden so schnell wie möglich eine Lösung für dich finden.',
        icon: 'ri-customer-service-2-line',
        name: 'Support schreiben',
        link: {
          href: 'mailto:support@storylinerlabs.com',
          label: 'E-Mail senden',
        },
      },
      {
        description:
          'Wir haben ein Verzeichnis von professionellen Content-Erstellern weltweit, an die wir dich verweisen können.',
        icon: 'ri-user-2-line',
        name: 'Experten beauftragen',
        link: {
          href: 'mailto:expert@storylinerlabs.com',
          label: 'Anfrage senden',
        },
      },
      // {
      //   description:
      //     'Verbinde dich mit anderen Nutzern, um Hilfe bei der Gestaltung des perfekten kreativen Workflows zu erhalten.',
      //   icon: 'ri-slack-line',
      //   name: 'Community fragen',
      //   link: {
      //     href: RouteEnum.WEBSITE_COMMUNITY_DE,
      //     label: 'Community beitreten',
      //   },
      // },
      {
        description:
          'Folge unserem Changelog, um über aktuelle Updates deines Workspaces informiert zu bleiben.',
        icon: 'ri-notification-badge-line',
        name: 'Changelog lesen',
        link: {
          href: RouteEnum.WEBSITE_RELEASE_NOTES_DE,
          label: 'Änderungen anzeigen',
        },
      },
      {
        description:
          'Durchsuche die Storyliner-Dokumentation. Dort gibt es wahrscheinlich bereits einen Artikel für dein Problem.',
        icon: 'ri-file-search-line',
        name: 'Dokumentation durchsuchen',
        link: {
          href: RouteEnum.WEBSITE_HELP_CENTER_DE,
          label: 'Bald verfügbar…',
        },
      },
      {
        description:
          'Lerne Best Practices und gestalte deinen kreativen Workflow mit den umfassenden Storyliner-Anleitungen.',
        icon: 'ri-treasure-map-line',
        name: 'Tutorials absolvieren',
        link: {
          href: RouteEnum.WEBSITE_HELP_CENTER_DE,
          label: 'Bald verfügbar…',
        },
      },
    ],
  },
  seo: {
    description:
      'Hast du Fragen zum Produkt? Unser Help-Center bietet einen ersten Startpunkt für deine Fragen.',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Help-Center - Storyliner',
  },
};
