/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// 404
export default {
  seo: {
    description: 'The page you are looking for does not exist or has been removed or moved.',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Page not found - Storyliner',
  },
  hero: {
    title: 'Page not found',
    description: 'The page you are looking for does not exist or has been removed or moved.',
    link: {
      label: 'To the home page',
    },
  },
};
