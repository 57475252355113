/* eslint-disable import/no-anonymous-default-export */

// import types
import { RouteEnum, PriceBoxColorEnum } from '../../../types';

// import config
import { storylinerRootDomain } from '../../../../config';

// components
export default {
  ClickToTweet: {
    link: {
      label: 'Jetzt zwitschern',
    },
  },
  BlogPost: {
    readMore: {
      label: 'Weitere Ressourcen',
    },
  },
  EarlyAccessForm: {
    email: {
      placeholder: 'don@sterling-draper.de',
      error: 'Bitte gib eine gültige E-Mail-Adresse ein.',
    },
    privacy: {
      text: `Wir beachten den <a href="${storylinerRootDomain}${RouteEnum.WEBSITE_PRIVACY_EN}">Datenschutz</a>.`,
      tooltip:
        'Informationen zum Protokollieren deiner Anmeldung, dem Versand von E-Mails über Convertkit und AWS, der statistischen Auswertung sowie über deine Abmeldemöglichkeiten erhälst du in unserer Datenschutzerklärung.',
    },
    submit: {
      label: 'Zugang erhalten',
      error:
        'Beim Registrieren ist ein Fehler aufgetreten. Bitte wende dich an unser Support-Team: support@storylinerlabs.com',
    },
  },
  EarlyAccessModal: {
    text: 'Erhalte unverbindlich Zugang zu deiner Kreativplattform sowie Produkt-Updates und starte mit dem Erstellen überzeugender Inhalte. Wir sind ein kleines engagiertes Team mit großen Plänen und einer noch größeren Warteliste. Wir bitten dich also im Voraus um etwas Geduld…',
    title:
      'Storyliner ist derzeit in einer <span class="has-marker">exklusiven</span> Beta-Version verfügbar',
  },
  EarlyAccessSection: {
    callToAction: {
      label: 'Inhaltserstellung starten',
    },
    creditCard: 'Kostenlose Testversion. Keine Kreditkarte erforderlich.',
    intro: 'Vereinfache deine kreativen Arbeitsabläufe und starte deine kostenlose Testversion.',
    title: 'Das Erstellen von Inhalten war nie <span class="has-marker">produktiver</span>',
  },
  EmbedOptin: {
    Youtube: {
      buttonText: 'Youtube-Video laden',
      infoText:
        'Wenn du das Video lädst, erklärst du dich damit einverstanden, dass deine Daten an Youtube übermittelt werden. Weitere Informationen findest in unserer <a href="https://storylinerlabs.com/de/datenschutz/">Datenschutzerklärung</a>.',
    },
  },
  GetDemoSection: {
    callToAction: {
      label: 'Demo anfordern',
      href: 'mailto:demo@storylinerlabs.com',
    },
    tour: 'Erhalte eine personalisierte Tour',
    intro:
      'Wenn Inhalte dein Geschäft antreiben, ist die Wahl eines neuen Tools eine große Sache. Begleite einen unserer Experten auf einer Live-Tour und erhalte die Antworten, die brauchst.',
    title: 'Möchtest du Storyliner in <span class="has-marker">Aktion</span> sehen?',
  },
  ImageTabs: {
    soon: {
      tooltip: 'Bald',
    },
  },
  ProductUpdatesForm: {
    email: {
      placeholder: 'don@sterling-draper.de',
      error: 'Bitte gib eine gültige E-Mail-Adresse ein.',
    },
    privacy: {
      text: `Wir beachten den <a href="${storylinerRootDomain}${RouteEnum.WEBSITE_PRIVACY_EN}">Datenschutz</a>.`,
      tooltip:
        'Informationen zum Protokollieren deiner Anmeldung, dem Versand von E-Mails über Convertkit und AWS, der statistischen Auswertung sowie über deine Abmeldemöglichkeiten erhälst du in unserer Datenschutzerklärung.',
    },
    submit: {
      label: 'Abonnieren',
      error:
        'Beim Registrieren ist ein Fehler aufgetreten. Bitte wende dich an unser Support-Team: support@storylinerlabs.com',
    },
  },
  NewsletterForm: {
    email: {
      placeholder: 'don@sterling-draper.de',
      error: 'Bitte gib eine gültige E-Mail-Adresse ein.',
    },
    privacy: {
      text: `Wir beachten den <a href="${storylinerRootDomain}${RouteEnum.WEBSITE_PRIVACY_EN}">Datenschutz</a>.`,
      tooltip:
        'Informationen zum Protokollieren deiner Anmeldung, dem Versand von E-Mails über Convertkit und AWS, der statistischen Auswertung sowie über deine Abmeldemöglichkeiten erhälst du in unserer Datenschutzerklärung.',
    },
    submit: {
      label: 'Abonnieren',
      error:
        'Beim Registrieren ist ein Fehler aufgetreten. Bitte wende dich an unser Support-Team: support@storylinerlabs.com',
    },
  },
  NewsletterSection: {
    intro:
      'Abonniere unseren Newsletter und erhalte regelmäßig Updates und Inspiration, einzigartige Perspektiven und Analysen zur Zukunft der Content-Erstellung.',
    title:
      'Verpasse kein <span class="has-marker">Update</span> zur Zukunft der Inhaltserstellung.',
  },
  PricingTable: {
    billingCycle: {
      annually: 'Jährlich',
      monthly: 'Monatlich',
    },
    chooseLater:
      'Melde dich zunächst für eine Testversion an. Sobald du ein Abonnement abgeschlossen hast, kannst du im Bereich "Abrechnung" deines Workspaces ein Upgrade auf eine höhere Verarbeitungsstufe vornehmen.',
    creativeEnhancement: {
      text: 'an Kreativitätssteigerung',
      tooltip:
        'Unter dem Begriff "Kreativitätssteigerung" fassen wir die Nutzung unserer KI-Funktionen wie "Text-Generierung", "Inhaltsanalyse" oder "Maschinen-Übersetzung" zusammen.',
    },
    currency: {
      dollar: 'USD',
      euro: 'EUR',
    },
    discount: {
      annually: 'Spare ~2 Monate bei jährlicher Abrechnung',
      monthly: 'Wechsle auf jährliche Abrechnung und spare 2 Monate',
    },
    mostPopular: 'Am beliebtesten',
    plans: [
      {
        description:
          'Für Kreative und Content-Ersteller, die ihre kreativen Workflows auf die nächste Stufe bringen und die Qualität und Quantität ihrer Inhalte steigern wollen.',
        features: [
          {
            id: 'unlimited-blocks',
            name: '<strong>Unbegrenzte Anzahl von Inhalten</strong>',
            soon: false,
          },
          {
            id: 'members',
            name: '<strong>Bis zu 2 Bearbeiter</strong>, unbegrenzte Betrachter',
            soon: false,
          },
          {
            id: 'language',
            name: '1 Inhaltssprache',
            soon: false,
          },
          {
            id: 'creative-features',
            name: 'Alle Kreationsfunktionen',
            soon: false,
          },
        ],
        id: 'creator',
        name: 'Creator',
        priceBox: {
          color: PriceBoxColorEnum.yellow,
          price: {
            annually: {
              description: 'pro Workspace/Monat – Jährliche Abrechnung',
              dollar: '$29',
              euro: '€24',
            },
            callToAction: '14 Tage kostenlos testen',
            monthly: {
              description: 'pro Workspace/Monat – Monatliche Abrechnung',
              dollar: '$35',
              euro: '€29',
            },
          },
        },
        runs: [
          {
            annually: {
              dollar: '$29',
              euro: '€24',
            },
            id: 'creator-500',
            label: '500 Durchläufe pro Monat',
            monthly: {
              dollar: '$35',
              euro: '€29',
            },
          },
          {
            annually: {
              dollar: '$59',
              euro: '€49',
            },
            id: 'creator-1000',
            label: '1.000 Durchläufe pro Monat',
            monthly: {
              dollar: '$69',
              euro: '€59',
            },
          },
        ],
      },
      {
        description:
          'Für Inhouse-Teams mit wachsendem Bedarf an Qualitätsinhalten, die ihre Zusammenarbeit verbessern und ihre Kreativ-Leistung steigern wollen.',
        features: [
          {
            id: 'creator-plan',
            name: '<strong>Alles im Creator-Plan</strong>, plus',
            soon: false,
          },
          {
            id: 'members',
            name: '<strong>Unbegrenzte Bearbeiter</strong> und Betrachter',
            soon: false,
          },
          {
            id: 'languages',
            name: '2 Inhaltssprachen',
            soon: true,
          },
          {
            id: 'integrations',
            name: 'Export-Integrationen',
            soon: true,
          },
        ],
        id: 'team',
        name: 'Team',
        priceBox: {
          color: PriceBoxColorEnum.anakiwa,
          price: {
            annually: {
              description: 'pro Workspace/Monat – Jährliche Abrechnung',
              dollar: '$89',
              euro: '€79',
            },
            callToAction: '14 Tage kostenlos testen',
            monthly: {
              description: 'pro Workspace/Monat – Monatliche Abrechnung',
              dollar: '$109',
              euro: '€99',
            },
          },
        },
        runs: [
          {
            annually: {
              dollar: '$89',
              euro: '€79',
            },
            id: 'team-2000',
            label: '2.000 Durchläufe pro Monat',
            monthly: {
              dollar: '$109',
              euro: '€99',
            },
          },
          {
            annually: {
              dollar: '$229',
              euro: '€209',
            },
            id: 'team-5000',
            label: '5.000 Durchläufe pro Monat',
            monthly: {
              dollar: '$279',
              euro: '€249',
            },
          },
          {
            annually: {
              dollar: '$469',
              euro: '€419',
            },
            id: 'team-10000',
            label: '10.000 Durchläufe pro Monat',
            monthly: {
              dollar: '$569',
              euro: '€499',
            },
          },
        ],
      },
      {
        description:
          'Für größere Organisationen, die ihren Content-Betrieb auf verschiedene Sprachen skalieren wollen sowie erweiterte Sicherheit und Support benötigen.',
        features: [
          {
            id: 'team-plan',
            name: '<strong>Alles im Team-Plan</strong>, plus',
            soon: false,
          },
          {
            id: 'integrations',
            name: '<strong>Alle verfügbaren Sprachen und Integrationen</strong>',
            soon: false,
          },
          {
            id: 'security',
            name: 'SAML-basiertes SSO',
            soon: false,
          },
          {
            id: 'support',
            name: 'Bevorzugter Support',
            soon: false,
          },
        ],
        id: 'enterprise',
        name: 'Enterprise',
        priceBox: {
          color: PriceBoxColorEnum.desertStorm,
          price: {
            annually: {
              description: 'Jährliche Abrechnung',
              dollar: 'Individuell',
              euro: 'Individuell',
            },
            callToAction: 'Kontakt aufnehmen',
            monthly: {
              description: 'Jährliche Abrechnung',
              dollar: 'Individuell',
              euro: 'Individuell',
            },
          },
        },
        runs: [
          {
            annually: {
              dollar: 'Individuell',
              euro: 'Individuell',
            },
            id: 'enterprise-custom',
            label: 'Individuelle Durchläufe pro Monat',
            monthly: {
              dollar: 'Individuell',
              euro: 'Individuell',
            },
          },
        ],
      },
    ],
    specialPlans:
      'Wir haben auch spezielle Pläne für Non-Profit- und Early-Stage-Startups. <a href="mailto:sales@storylinerlabs.com">Vertrieb kontaktieren</a>.',
    soon: 'Bald',
  },
  PrivacyActions: {
    alerts: {
      facebookPixel:
        'Du hast dich erfolgreich von der Erfassung durch den Facebook-Pixel abgemeldet.',
      googleAnalytics:
        'Du hast dich erfolgreich von der Erfassung durch Google Analytics abgemeldet.',
    },
  },
  ReleaseNotes: {
    app: {
      WEBAPP: 'WebApp',
      CHROMEEXTENSION: 'ChromeExtension',
    },
    category: {
      FEATURES: 'NeueFunktionen',
      IMPROVEMENTS: 'Verbesserungen',
    },
  },
  ThemeSwitcher: {
    dark: 'Zum hellen Design wechseln',
    light: 'Zum dunklen Design wechseln',
  },
};
