/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// genres
export default {
  genres: {
    dividerTopDark: 'divider-top-tuatuara-1',
    dividerTop: 'divider-top-desertstorm-1',
    title: 'The Storyliner Genres',
  },
  hero: {
    intro:
      'Get more variety in your corporate communication with our genres and storylines. During the creation process, you can use different professional forms of presentation. Our technology adapts each type individually to your content piece and your communication strategy.',
    title: '<span class="has-marker">Wow</span>, instead of blah-blah.',
  },
  innovationPartner: {
    callToAction: {
      label: 'Become an innovation partner',
      link: 'mailto:innovation@storylinerlabs.com',
    },
    dividerBottomDark: 'divider-bottom-tawnyport-1',
    dividerBottom: 'divider-bottom-allports-1',
    intro:
      'Become an innovation partner and, like other content creatives or communication teams, develop new forms of presentation or automate existing formats. Interested? Write to us.',
    logos: [
      {
        alt: 'Logo Newsaktuell',
        src: 'storyliner/logos/logo-newsaktuell_fvixyr',
      },
      {
        alt: 'Logo Hyconnect',
        src: 'storyliner/logos/logo-hyconnect_vjgzt0',
      },
      {
        alt: 'Logo awork',
        src: 'storyliner/logos/logo-awork_y2bgaf',
      },
    ],
    title: 'Do you want to develop your own genre?',
  },
  seo: {
    description:
      'Get more diversity in your corporate communication with our genres and storylines. Discover genres …',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Genres - Storyliner',
  },
};
