/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */

// shared types
export type FixMeLater = any;

export type Locale = 'de' | 'en';

export enum LocaleEnum {
  DE = 'de',
  EN = 'en',
}

export type Theme = 'light' | 'dark';

export enum ThemeEnum {
  LIGHT = 'light',
  DARK = 'dark',
}

export type SortDirection = 'asc' | 'desc';

export enum SortDirectionEnum {
  ASC = 'asc',
  DESC = 'desc',
}

export type SortKey = 'createdAt' | 'lastModifiedAt' | 'title';

export enum SortKeyEnum {
  CREATEDAT = 'createdAt',
  LASTMODIFIEDAT = 'lastModifiedAt',
  TITLE = 'title',
}
