// routes

export type Route =
  | '/de/account/create-workspace/'
  | '/de/account/create-workspace/'
  | '/account/create-workspace/'
  | '/de/account/notifications/'
  | '/account/notifications/'
  | '/de/account/privacy/'
  | '/account/privacy/'
  | '/de/account/profile/'
  | '/account/profile/'
  | '/de/account/settings/'
  | '/account/settings/'
  | '/de/account/workspaces/'
  | '/account/workspaces/'
  | '/de/auth/callback/'
  | '/auth/callback/'
  | '/de/auth/confirm/'
  | '/auth/confirm/'
  | '/de/auth/signin/'
  | '/auth/signin/'
  | '/de/auth/signout/'
  | '/auth/signout/'
  | '/de/auth/signup/'
  | '/auth/signup/'
  | '/de/auth/verify/'
  | '/auth/verify/'
  | '/de/[workspaceId]/blocks/'
  | '/[workspaceId]/blocks/'
  | '/de/[workspaceId]/block/[blockId]/'
  | '/[workspaceId]/block/[blockId]/'
  | '/de/[workspaceId]/collections/'
  | '/[workspaceId]/collections/'
  | '/de/[workspaceId]/guidelines/[component]/'
  | '/[workspaceId]/guidelines/[component]/'
  | '/de/[workspaceId]/hub/'
  | '/[workspaceId]/hub/'
  | '/de/'
  | '/'
  | '/de/[workspaceId]/stories/'
  | '/[workspaceId]/stories/'
  | '/de/[workspaceId]/stories/[storyId]/[docId]/'
  | '/[workspaceId]/stories/[storyId]/[docId]/'
  | '/de/[workspaceId]/stories/[storyId]/storyline/'
  | '/[workspaceId]/stories/[storyId]/storyline/'
  | '/de/[workspaceId]/trash/'
  | '/[workspaceId]/trash/'
  | '/de/[workspaceId]/workspace/languages/'
  | '/[workspaceId]/workspace/languages/'
  | '/de/[workspaceId]/workspace/members/'
  | '/[workspaceId]/workspace/members/'
  | '/de/[workspaceId]/workspace/profile/'
  | '/[workspaceId]/workspace/profile/'
  | '/de/ueber-uns/'
  | '/about/'
  | '/de/blog/'
  | '/blog/'
  | '/de/community/'
  | '/community/'
  | '/de/early-access-bestaetigt/'
  | '/early-access-confirmed/'
  | '/de/early-access-danke/'
  | '/early-access-thank-you/'
  | '/de/funktionen/'
  | '/features/'
  | '/de/genres/'
  | '/genres/'
  | '/de/help-center/'
  | '/help-center/'
  | '/de/'
  | '/'
  | '/de/wie-es-funktioniert/'
  | '/how-it-works/'
  | '/de/impressum/'
  | '/legal-notice/'
  | '/de/manifest/'
  | '/manifesto/'
  | '/de/newsletter-anmeldung-bestaetigt/'
  | '/newsletter-registration-confirmed/'
  | '/de/newsletter-anmeldung-danke/'
  | '/newsletter-registration-thank-you/'
  | '/de/blog/tag/news/'
  | '/blog/tag/news/'
  | '/de/presse/'
  | '/press/'
  | '/de/preise/'
  | '/pricing/'
  | '/de/datenschutz/'
  | '/privacy/'
  | '/de/changelog/'
  | '/changelog/'
  | '/de/nutzungsbedingungen/'
  | '/terms-of-service/';

export enum RouteEnum {
  // Account routes
  ACCOUNT_CREATE_WORKSPACE_DE = '/de/account/create-workspace/',
  ACCOUNT_CREATE_WORKSPACE_EN = '/account/create-workspace/',
  ACCOUNT_NOTIFICATIONS_DE = '/de/account/notifications/',
  ACCOUNT_NOTIFICATIONS_EN = '/account/notifications/',
  ACCOUNT_PRIVACY_DE = '/de/account/privacy/',
  ACCOUNT_PRIVACY_EN = '/account/privacy/',
  ACCOUNT_PROFILE_DE = '/de/account/profile/',
  ACCOUNT_PROFILE_EN = '/account/profile/',
  ACCOUNT_SETTINGS_DE = '/de/account/settings/',
  ACCOUNT_SETTINGS_EN = '/account/settings/',
  ACCOUNT_WORKSPACES_DE = '/de/account/workspaces/',
  ACCOUNT_WORKSPACES_EN = '/account/workspaces/',

  // Auth routes
  AUTH_CALLBACK_DE = '/de/auth/callback/',
  AUTH_CALLBACK_EN = '/auth/callback/',
  AUTH_CONFIRM_DE = '/de/auth/confirm/',
  AUTH_CONFIRM_EN = '/auth/confirm/',
  AUTH_SIGN_IN_DE = '/de/auth/signin/',
  AUTH_SIGN_IN_EN = '/auth/signin/',
  AUTH_SIGN_OUT_DE = '/de/auth/signout/',
  AUTH_SIGN_OUT_EN = '/auth/signout/',
  AUTH_SIGN_UP_DE = '/de/auth/signup/',
  AUTH_SIGN_UP_EN = '/auth/signup/',
  AUTH_VERIFY_DE = '/de/auth/verify/',
  AUTH_VERIFY_EN = '/auth/verify/',

  // APP routes
  WEBAPP_BLOCKS_DE = '/de/[workspaceId]/blocks/',
  WEBAPP_BLOCKS_EN = '/[workspaceId]/blocks/',
  WEBAPP_BLOCK_DE = '/de/[workspaceId]/block/[blockId]/',
  WEBAPP_BLOCK_EN = '/[workspaceId]/block/[blockId]/',
  WEBAPP_COLLECTIONS_DE = '/de/[workspaceId]/collections/',
  WEBAPP_COLLECTIONS_EN = '/[workspaceId]/collections/',
  WEBAPP_GUIDELINES_DE = '/de/[workspaceId]/guidelines/[component]/',
  WEBAPP_GUIDELINES_EN = '/[workspaceId]/guidelines/[component]/',
  WEBAPP_HUB_DE = '/de/[workspaceId]/hub/',
  WEBAPP_HUB_EN = '/[workspaceId]/hub/',
  WEBAPP_INDEX_DE = '/de/',
  WEBAPP_INDEX_EN = '/',
  WEBAPP_STORIES_DE = '/de/[workspaceId]/stories/',
  WEBAPP_STORIES_EN = '/[workspaceId]/stories/',
  WEBAPP_STORY_DE = '/de/[workspaceId]/stories/[storyId]/[docId]/',
  WEBAPP_STORY_EN = '/[workspaceId]/stories/[storyId]/[docId]/',
  WEBAPP_STORY_STORYLINE_DE = '/de/[workspaceId]/stories/[storyId]/storyline/',
  WEBAPP_STORY_STORYLINE_EN = '/[workspaceId]/stories/[storyId]/storyline/',
  WEBAPP_TRASH_DE = '/de/[workspaceId]/trash/',
  WEBAPP_TRASH_EN = '/[workspaceId]/trash/',
  WEBAPP_WORKSPACE_LANGUAGES_DE = '/de/[workspaceId]/workspace/languages/',
  WEBAPP_WORKSPACE_LANGUAGES_EN = '/[workspaceId]/workspace/languages/',
  WEBAPP_WORKSPACE_MEMBERS_DE = '/de/[workspaceId]/workspace/members/',
  WEBAPP_WORKSPACE_MEMBERS_EN = '/[workspaceId]/workspace/members/',
  WEBAPP_WORKSPACE_PROFILE_DE = '/de/[workspaceId]/workspace/profile/',
  WEBAPP_WORKSPACE_PROFILE_EN = '/[workspaceId]/workspace/profile/',

  // Website routes
  WEBSITE_ABOUT_DE = '/de/ueber-uns/',
  WEBSITE_ABOUT_EN = '/about/',
  WEBSITE_BLOG_DE = '/de/blog/',
  WEBSITE_BLOG_EN = '/blog/',
  WEBSITE_COMMUNITY_DE = '/de/community/',
  WEBSITE_COMMUNITY_EN = '/community/',
  WEBSITE_EARLY_ACCESS_CONFIRMED_DE = '/de/early-access-bestaetigt/',
  WEBSITE_EARLY_ACCESS_CONFIRMED_EN = '/early-access-confirmed/',
  WEBSITE_EARLY_ACCESS_THANK_YOU_DE = '/de/early-access-danke/',
  WEBSITE_EARLY_ACCESS_THANK_YOU_EN = '/early-access-thank-you/',
  WEBSITE_FEATURES_DE = '/de/funktionen/',
  WEBSITE_FEATURES_EN = '/features/',
  WEBSITE_GENRES_DE = '/de/genres/',
  WEBSITE_GENRES_EN = '/genres/',
  WEBSITE_HELP_CENTER_DE = '/de/help-center/',
  WEBSITE_HELP_CENTER_EN = '/help-center/',
  WEBSITE_HOME_DE = '/de/',
  WEBSITE_HOME_EN = '/',
  WEBSITE_HOWITWORKS_DE = '/de/wie-es-funktioniert/',
  WEBSITE_HOWITWORKS_EN = '/how-it-works/',
  WEBSITE_LEGAL_NOTICE_DE = '/de/impressum/',
  WEBSITE_LEGAL_NOTICE_EN = '/legal-notice/',
  WEBSITE_MANIFESTO_DE = '/de/manifest/',
  WEBSITE_MANIFESTO_EN = '/manifesto/',
  WEBSITE_NEWSLETTER_CONFIRMED_DE = '/de/newsletter-anmeldung-bestaetigt/',
  WEBSITE_NEWSLETTER_CONFIRMED_EN = '/newsletter-registration-confirmed/',
  WEBSITE_NEWSLETTER_THANK_YOU_DE = '/de/newsletter-anmeldung-danke/',
  WEBSITE_NEWSLETTER_THANK_YOU_EN = '/newsletter-registration-thank-you/',
  WEBSITE_NEWS_DE = '/de/blog/tag/news/',
  WEBSITE_NEWS_EN = '/blog/tag/news/',
  WEBSITE_PRESS_DE = '/de/presse/',
  WEBSITE_PRESS_EN = '/press/',
  WEBSITE_PRICING_DE = '/de/preise/',
  WEBSITE_PRICING_EN = '/pricing/',
  WEBSITE_PRIVACY_DE = '/de/datenschutz/',
  WEBSITE_PRIVACY_EN = '/privacy/',
  WEBSITE_RELEASE_NOTES_DE = '/de/changelog/',
  WEBSITE_RELEASE_NOTES_EN = '/changelog/',
  WEBSITE_TERMS_OF_SERVICE_DE = '/de/nutzungsbedingungen/',
  WEBSITE_TERMS_OF_SERVICE_EN = '/terms-of-service/',
}
