/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// newsletter confirmed
export default {
  blog: {
    title:
      'Learn more about the <span class="has-marker">exciting</span> future of content creation',
  },
  hero: {
    title: 'Subscription confirmed',
    intro:
      'You have successfully registered for our newsletter. Thanks for your subscription. Soon you receive stories about the future of content creation.',
  },
  seo: {
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Subscription confirmed - Storyliner',
    description:
      'You have successfully registered for our newsletter. Thanks for your registration. Soon you receive stories about the future of content creation.',
  },
};
