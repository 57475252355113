/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// press
export default {
  clippings: {
    title: 'What others write about us',
  },
  downloads: {
    dividerBottom: 'divider-bottom-allports-2',
    dividerBottomDark: 'divider-bottom-tawnyport-1',
    items: [
      {
        link: {
          href: 'https://drive.google.com/drive/folders/17ZEipBh6bY8mMrVuW0IB63ux7CxjVsbN?usp=sharing',
          icon: 'ri-download-2-line',
          label: 'Download press kit',
        },
        text: 'Looking for company information and our logo or official colors?',
        title: 'Press information',
      },
      {
        link: {
          href: 'https://drive.google.com/drive/folders/183pdSlKn-9-yaRjupEAB5HIMJwPlV_8g?usp=sharing',
          icon: 'ri-file-download-line',
          label: 'Download information',
        },
        text: 'Looking for product images and feature descriptions?',
        title: 'Product information',
      },
      {
        link: {
          href: 'mailto:pr@storylinerlabs.com',
          icon: 'ri-mail-send-line',
          label: 'Send inquiry',
        },
        text: 'Do you need additional information or want to organize an interview?',
        title: 'Press contact',
      },
    ],
  },
  hero: {
    intro:
      'Stay up to date with Storyliner corporate news and media coverage. Download our press materials or request more information.',
    title: 'Press room',
  },
  news: {
    title: 'Our latest news and updates',
  },
  seo: {
    description:
      'Stay up to date with Storyliner corporate news and media coverage and download our press materials. Learn more …',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Press room - Storyliner',
  },
};
