/* eslint-disable import/prefer-default-export */

// import node_modules
import { atomWithImmer } from 'jotai/immer';

// import types
import { Locale } from '../../types';

// define atoms
export const authSessionIdAtom = atomWithImmer<string | null>(null);

export const authUserEmailAtom = atomWithImmer<string | null>(null);

export const authUserIdAtom = atomWithImmer<string | null>(null);

export const earlyAccessModalAtom = atomWithImmer<boolean>(false);

export const localeAtom = atomWithImmer<Locale>('en');
