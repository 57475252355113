/* eslint-disable import/no-anonymous-default-export */

// import types
import { RouteEnum } from '../../../types';

// import config
import { storylinerRootDomain } from '../../../../config';

// pricing
export default {
  hero: {
    title: '<span class="has-marker">Unleash</span> your creative potential',
    intro: 'Choose a plan that fits you and your content operation.',
  },
  faq: {
    questions: [
      {
        title: 'What is a Workspace?',
        text: 'Your user account in Storyliner comes with a workspace that contains the communication guidelines, blocks, or stories of your content project. You can collaborate with the rest of your team in a workspace by viewing, sharing, or editing content together, depending on the role of each team member. You can join multiple workspaces with your user account.',
      },
      {
        title: 'Which user roles are there?',
        text: 'A Storyliner workspace has four user roles: owner, administrator, creator (editor), or collaborators. Owners and administrators can manage workspace members and billing and have all the rights of creators. Creators can edit content, get inspiration, and optimize and translate content. Collaborators have access only to shared content: they can either only view, comment, or edit it.',
      },
      {
        title: 'What is meant by Creative Enhancement?',
        text: 'Under the term "Creativity Enhancement" we summarize the use of our AI functions such as "text generation", "content analysis" or "machine translation". These functions are designed to inspire you, give you quick feedback on quality, or help you formulate better texts - in other words, enhance your creativity.',
      },
      {
        title: 'What happens when I reach my limit?',
        text: "When you've used up your runs and reached your monthly limit of creative enhancement, you can either upgrade or wait until the next month. You will not automatically be charged more. Unused runs will expire and will not be carried over to the next month.",
      },
      {
        title: 'What does unlimited creation of content mean?',
        text: 'You can create unlimited blocks, documents, stories or storylines with any plan. So you can write four blog articles a month or 100+. You can have unlimited documents per story, collect blocks as you like, or save storylines as needed for later. We only charge you more, for more passes of creativity boost and not for creating and editing content.',
      },
      {
        title: 'What do the creation features include?',
        text: 'Each workspace and plan can access all creation features. These consist of managing communication guidelines - such as audience, objective or language rules - creative briefings, Storyliner genres, block management, the multi-document editor with all its formatting and collaboration features, file and media upload, and download and export functions.',
      },
      {
        title: 'Is the generated content subject to copyright?',
        text: 'The machine-generated text snippets are not subject to copyright. The majority of jurisdictions worldwide (including Germany, Europe, USA) do not recognize a machine as the creator. This is true despite the original character of the generated text modules. Therefore you can use the suggestions directly in your texts without changing them.',
      },
      {
        title: 'What are content languages?',
        text: 'The Storyliner applications are available in English and German. For these languages you also get examples and generated inspiration. Your content, on the other hand, can be translated and edited in different languages and different locales, such as British English or Brazilian Portuguese.',
      },
      {
        title: 'What export integrations are available?',
        text: "In addition to downloading your content in PDF, TXT, DOCX or MD format, you can also export it via Zapier integration. With Zapier, you can automate your work processes and connect two tools without developer support - for example, Storyliner with WordPress. Besides Zapier, we're working on other integrations to export content directly to your content management system or social media profile.",
      },
      {
        title: 'How do subscriptions work?',
        text: 'You sign up for subscriptions per workspace in Storyliner. Subscriptions renew automatically at the end of each billing period (either monthly or annually) and are valid for a single workspace. With your user account, you can switch between multiple workspaces with active subscriptions.',
      },
      {
        title: 'What are the cancellation terms?',
        text: "You can cancel Storyliner every month or every year without giving any reason. Just write a message to our support. That's it. If you are not satisfied, we are not satisfied.",
      },
    ],
    intro: `Your question is not listed? You can find more help and answers in our <a href="${storylinerRootDomain}${RouteEnum.WEBSITE_HELP_CENTER_EN}">Help Center</a>.`,
    title: 'Frequently asked questions',
  },
  pricing: {
    dividerBottomDark: 'divider-bottom-tuatuara-1',
    dividerBottom: 'divider-bottom-desertstorm-1',
    securityItems: [
      {
        id: 'gdpr',
        label: 'GDPR compliant',
        icon: 'ri-shield-check-line',
      },
      {
        id: 'ssl',
        label: 'SSL encrypted',
        icon: 'ri-lock-password-line',
      },
    ],
  },
  runsExplained: {
    cards: [
      {
        title: '6',
        description: 'AI-generated short-form texts such as headlines or call-to-actions',
      },
      {
        title: '3',
        description: 'AI-generated long-form texts such as introductions or summaries',
      },
      {
        title: '1x',
        description: 'content analysis, such as spelling, grammar or tonality checks',
      },
      {
        title: '1,000',
        description: 'characters machine-<br />translated text in the available languages',
      },
    ],
    infoText:
      'For example, users of the Creator plan create 4 blog articles and 30 posts for Facebook and LinkedIn as well as some marketing copy with 500 runs. Note: this specification is individual and may vary for your use case.',
    title: '<span class="has-marker">1 run</span> Creative Enhancement is equal to',
  },
  usedBy: {
    logos: [
      {
        alt: 'Logo Strobel & Walter',
        src: 'storyliner/logos/logo-strobel-walter_miu1pb',
      },
      {
        alt: 'Logo Hyggins.pet',
        src: 'storyliner/logos/logo-hyggins_j7warc',
      },
      {
        alt: 'Logo Paceheads',
        src: 'storyliner/logos/logo-paceheads_tcdj0e',
      },
      {
        alt: 'Logo Petsahoi',
        src: 'storyliner/logos/logo-pets-ahoi_ipzbzr',
      },
    ],
    title: 'You are in creative company',
  },
  seo: {
    description:
      'Unleash your creative potential. Choose a plan that fits you and your content operation. See pricing …',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Plans and prices - Storyliner',
  },
};
