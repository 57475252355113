/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// features
export default {
  features: [
    {
      description:
        'Mit Storyliner kannst du deine entwickelte Content-Strategie nicht nur festhalten, sondern auch direkt Umsetzen. Anhand von Kommunikations-Richtlinien helfen wir dir konsistent und Publikums-gerecht zu kommunizieren.',
      id: 'strategy',
      items: [
        {
          image: 'storyliner/website/storyliner-features-strategy-de_susogt',
          text: 'Schluss mit Ideenchaos und inkonsistenten Inhalten. Definiere deine Kommunikationsstrategie und hinterlege Ziele, Zielpublikum oder Sprachrichtlinien. Anhand deiner Angaben kannst du deine Inhalte kategorisieren und du erhältst Empfehlungen in Echtzeit.',
          title: 'Kommunikationsstrategie',
        },
        {
          image: 'storyliner/website/storyliner-features-strategy-de_susogt',
          text: 'Ein Styleguide ist das Fundament der Kommunikation für jede Marke. Mit Storyliner kannst du Sprachregeln, Ansprache, Wörterbücher und Schreibstandards festhalten und wir stellen sicher, dass du diese auch in verschiedenen Formaten über alle Kanäle hinweg einhälst.',
          title: 'Styleguide und Sprachregeln',
        },
        // {
        //   image: 'storyliner-features-strategy-de.png',
        //   text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        //   title: 'Publikum und Personas (bald)',
        // },
        // {
        //   image: 'storyliner-features-strategy-de.png',
        //   text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        //   title: 'Keywords und Hashtags (bald)',
        // },
      ],
      label: 'Strategie',
      title: 'Wo Strategie auf Kreation trifft',
    },
    {
      description:
        'Konzipiere deine Inhalte strategisch. Anhand von Kreativ-Briefings kannst du wichtige konzeptionelle Fragen zu deiner Story beantworten und die Zusammenarbeit im Team verbessern.',
      id: 'plan',
      items: [
        {
          image: 'storyliner/website/storyliner-features-plan-de_fs4nbs',
          text: 'Entwickle überzeugende Inhalte mit Kreativ-Briefings. Im Briefing für eine Story klärst du Ziele sowie Zielpublikum und du definierst die inhaltliche Struktur. Mit der Planung verbesserst du den Austausch im Team und legst die Grundlage für die generierte Inspiration und Inhaltsanalysen.',
          title: 'Kreativ-Briefings',
        },
        {
          image: 'storyliner/website/storyliner-features-plan-de_fs4nbs',
          text: 'Bekomme mehr Vielfalt in deine Unternehmenskommunikation mit unseren professionellen Genres. Während des Briefings kannst du zwischen inhaltlichen Darstellungsformen wählen. Auf dieser Basis werden anschließend narrative Strukturen für deine Kommunikationskanäle generiert.',
          title: 'Professionelle Genres',
        },
        {
          image: 'storyliner/website/storyliner-features-plan-de_fs4nbs',
          text: 'Beschleunige deinen Kreativprozess. Sobald du eine Storyline entwickelt hast, kannst du diese für später als Vorlage abspeichern und für die nächste Story wiederverwenden. Oder speichere ein komplettes Briefing, die angelegten Kanälen und Storyline als Format ab. So kannst du deinen Erstellungsprozess weiter automatisieren.',
          title: 'Wiederkehrende Formate und Storylines',
        },
        {
          image: 'storyliner/website/storyliner-features-plan-de_fs4nbs',
          text: 'Flexibel und anpassbar für jeden Workflow – Plane deinen Redaktionsplan anhand von Kanban-, Listen- oder Galerieansichten. So wird Ideen- und Kommunikationsmanagement transparent und einfach für jedes Content-Team.',
          title: 'Flexible Verwaltungsansichten',
        },
      ],
      label: 'Planen',
      title: 'Gut geplant ist halb erstellt',
    },
    {
      description:
        'Mach Schluss mit dem nervenden Hin- und Herkopieren zwischen Tools und unzähligen Orten, wo Inhalte, Gedanken und Notizen gespeichert sind. Mit den Storyliner Recherche-Funktionen organisierst du dein kreatives Chaos.',
      id: 'research',
      items: [
        {
          image: 'storyliner/website/storyliner-features-research-de_hbdrnd',
          text: 'Sammle, sortiere und strukturiere Texte, Bilder, Videos, Tweets oder Dateien zu einem Inhaltstück zentral an einem Ort. Du kannst die Blöcke während dem Kreieren direkt in deine Storyline oder Dokument einbauen.',
          title: 'Zentrale Blocksammlung',
        },
        {
          image: 'storyliner/website/storyliner-features-research-de_hbdrnd',
          text: 'Sammle Bilder, Textpassagen, Lesezeichen und mehr mit nur einem Klick direkt beim Recherchieren. Mit unserer Chrome-Browser-Erweiterung oder mobilen Apps (bald verfügbar) kannst du ohne Umwege deine gefundenen Quellen an Storyliner senden und weiterverarbeiten.',
          title: 'Clipping-Apps',
        },
        // {
        //   image: 'storyliner-features-research-de.png',
        //   text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        //   title: 'Quellenvalidierung (bald)',
        // },
        // {
        //   image: 'storyliner-features-research-de.png',
        //   text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        //   title: 'Inhaltsempfehlungen (bald)',
        // },
      ],
      label: 'Recherchieren',
      title: 'Erfasse schnell was wichtig ist',
    },
    {
      description:
        'Stundenlang auf das leere Blatt Papier starren war gestern. Heute startest du deine Inhaltserstellung mit Struktur, generierten Textbausteinen und Inspiration. Komponiere in kürzester Zeit Inhalte für deine gesamte Unternehmenskommunikation.',
      id: 'create',
      items: [
        {
          image: 'storyliner/website/storyliner-features-create-de_zg51ng',
          text: 'Erst die Storyline, dann die Kanäle – Entwickle ein Konzept einmalig und überführe es in verschiedene Kommunikationskanäle. Mit unserer Multi-Dokumenten-Kreativumgebung kannst du deine Inhalte übersichtlich für jeden Kommunikationskanal anpassen, via Drag-and-drop-Funktion arrangieren oder als wiederkehrende Formate speichern.',
          title: 'Multi-Dokument-Kreativumgebung',
        },
        {
          image: 'storyliner/website/storyliner-features-create-de_zg51ng',
          text: 'Wie sich deine Kommunikation aus vielen kleinen Bestandteilen zu einem großen Ganzen zusammensetzt, so funktioniert auch unsere flexible Kreativumgebung. Du kannst Inhalte anhand von Konzept-Blöcken hervorheben und Strukturieren. Via Schnellmenü kannst du Beispiele und Inspiration laden, Inhalte automatisch übersetzen lassen, gespeicherte Blöcke einfügen oder Medien in Sekunden laden.',
          title: 'Konzept-Blöcke und Schnellmenü',
        },
        {
          image: 'storyliner/website/storyliner-features-create-de_zg51ng',
          text: 'Steigere deinen kreativen Output mit der Hilfe von Künstlicher Intelligenz. Basierend auf deinem Briefing und deinen Kommunikationsrichtlinien kannst du dir Texbausteine als Inspiriation generieren lassen und direkt in deine Inhalte übernehmen. Anhand von Beispielen und Vorlagen kannst du selbst überzeugende Inhalte entwickeln.',
          title: 'Generierte Texte und Vorlagen',
        },
        {
          image: 'storyliner/website/storyliner-features-create-de_zg51ng',
          text: 'Endlich Kollaboration ohne Chaos. Mit Storyliner kannst du gemeinsam am gleichen Dokument arbeiten, Feedback direkt auf Inhaltsebene erhalten und mittels Statusindikator kommen keine lästigen "Ist es schon fertig"-Fragen mehr. Mittels der integrierten Versionsverwaltung wird alles automatisch gespeichert.',
          title: 'Kollaborationsfunktionen',
        },
      ],
      label: 'Kreieren',
      title: 'Entfache deine Kreativität',
    },
    {
      description:
        'Inhaltserstellung wird immer komplexer. Deshalb bekommst du mit den Optimierungsfuntionen umfangreiche und leicht verständliche Analyse-Ergebnisse. So machst du aus guten, überzeugende Inhalte.',
      id: 'optimize',
      items: [
        {
          image: 'storyliner/website/storyliner-features-optimize-de_bn2pav',
          text: 'Hole alles aus deinen Inhalten heraus. Mit den umfangreichen und leicht verständlichen Inhaltsanalysen und Tipps eliminierst du orthografische Fehler, verbessert deine Rechtschreibung und Grammatik und stellst fehlerfreie Inhalte sicher.',
          title: 'Grammatik und Rechtschreibung',
        },
        {
          image: 'storyliner/website/storyliner-features-optimize-de_bn2pav',
          text: 'Bessere Ausdrucksweise und Kanal-übergreifende konsistente Inhalte – anhand von Emotionsanalysen und dem definierten Styleguide erhältst du Tipps und Verbesserungsvorschläge zu Formalität, Ansprache und deinem persönlichen Wörterbuch.',
          title: 'Tonalität und Sprachregeln',
        },
        // {
        //   image: 'storyliner-features-optimize-de.png',
        //   text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        //   title: 'Tipps und Heuristiken (bald)',
        // },
        // {
        //   image: 'storyliner-features-optimize-de.png',
        //   text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        //   title: 'Plagiatsprüfung (bald)',
        // },
      ],
      label: 'Optimieren',
      title: 'Feinschliff für deine Inhalte',
    },
    {
      description:
        'Storyliner integriert sich nahtlos in deine bestehende Technologie-Landschaft. So kannst du all deine Inhalte unkompliziert herunterladen oder direkt in die Applikation deiner Wahl exportieren.',
      id: 'publish',
      items: [
        {
          image: 'storyliner/website/storyliner-features-export-de_qy8d2y',
          text: 'Du hast deine Inhalte fertiggestellt? Dann kannst du diese in gängigen Dateiformate, wie PDF, DOCX, MD, HTML oder TXT und nach Kommunikationskanal aufbereitet herunterladen.',
          title: 'Dokumenten- und Mediendownload',
        },
        {
          image: 'storyliner/website/storyliner-features-export-de_qy8d2y',
          text: 'Exportiere deine Inhalte direkt in die Software, das Tool oder das Content-Management-System deiner Wahl mittels unserer Zapier-Schnittstelle. Durch Zapier kannst du Storyliner mit der App deiner Wahl verbinden. So zum Beispiel mit WordPress oder Google Docs.',
          title: 'Export via Zapier',
        },
        // {
        //   image: 'storyliner-features-export-de.png',
        //   text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        //   title: 'Zeitgesteuertes Veröffentlichen (bald)',
        // },
        // {
        //   image: 'storyliner-features-export-de.png',
        //   text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        //   title: 'Direkte Integrationen (bald)',
        // },
      ],
      label: 'Veröffentlichen',
      title: 'Exportiere wohin du willst',
    },
  ],
  hero: {
    intro:
      'Das Erstellen von Inhalten, wie du es kennst, ist aus der Tür – Inhaltserstellung, wie du sie wünscht, ist angekommen. Storyliner ist vollkommen vertraut und doch ganz anders. Entdecke unsere Funktionen entlang des kreativen Prozesses.',
    title:
      'Wir haben das Rad nicht neu erfunden, <span class="has-marker">nur</span> die Inhaltserstellung.',
  },
  seo: {
    description:
      'Das Erstellen von Inhalten, wie du es kennst, ist aus der Tür – Inhaltserstellung, wie du sie wünscht, ist angekommen. Entdecke unsere Funktionen entlang des kreativen Prozesses.',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Funktionen - Storyliner',
  },
  testimonial: {
    bgColor: 'energyYellow',
    color: 'black',
    colorDark: 'white',
    image: 'storyliner/testimonials/testimonial-suheda-turedi_ecgwgx',
    jobTitle: 'Gründerin & Designerin, suesmadewithlove.com',
    name: 'Suheda Türedi',
    quote:
      '„Seit ich Storyliner verwende, spare ich Zeit bei der Erstellung meiner Inhalte. Ein echtes Must-Have-Tool für alle Content-Marketer!“',
    snippet: 'snippet-yellow-1',
    snippetDark: 'snippet-tawnyport-1',
  },
};
