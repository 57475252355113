// import node_modules
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

// import content
import pagesDe from '../../content/pages/de';
import pagesEn from '../../content/pages/en';
import componentsDe from '../../content/components/de';
import componentsEn from '../../content/components/en';

// export modules
export const defaultNS = 'auth';

export const resources = {
  de: { ...pagesDe, ...componentsDe },
  en: { ...pagesEn, ...componentsEn },
} as const;

// init i18n
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS,
    fallbackLng: 'en',
    interpolation: { escapeValue: false },
    lng: 'en',
    react: { useSuspense: true },
    resources,
    returnNull: false,
  });

export { i18n };
