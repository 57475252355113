/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// release notes
export default {
  hero: {
    intro:
      'Follow our changelog to stay informed about current updates that affect the Storyliner applications and your workspace.',
    title: 'Changelog',
  },
  seo: {
    description:
      'Follow our changelog to stay informed about current updates that affect the Storyliner applications and your workspace. Read now.',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Changelog - Storyliner',
  },
};
