/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// press
export default {
  clippings: {
    title: 'Das schreiben andere über uns',
  },
  downloads: {
    dividerBottom: 'divider-bottom-allports-2',
    dividerBottomDark: 'divider-bottom-tawnyport-1',
    items: [
      {
        link: {
          href: 'https://drive.google.com/drive/folders/17ZEipBh6bY8mMrVuW0IB63ux7CxjVsbN?usp=sharing',
          icon: 'ri-download-2-line',
          label: 'Presskit herunterladen',
        },
        text: 'Suchst du nach Unternehmensinformationen oder unserem Logo?',
        title: 'Presseinfos',
      },
      {
        link: {
          href: 'https://drive.google.com/drive/folders/183pdSlKn-9-yaRjupEAB5HIMJwPlV_8g?usp=sharing',
          icon: 'ri-file-download-line',
          label: 'Produktinfos herunterladen',
        },
        text: 'Suchst du nach Produktbildern oder Funktionsbeschreibungen?',
        title: 'Produktinfos',
      },
      {
        link: {
          href: 'mailto:pr@storylinerlabs.com',
          icon: 'ri-mail-send-line',
          label: 'Anfrage senden',
        },
        text: 'Brauchst du mehr Informationen oder möchtest du ein Interview vereinbaren?',
        title: 'Pressekontakt',
      },
    ],
  },
  hero: {
    intro:
      'Bleibe auf dem Laufenden mit Storyliner Nachrichten und Medienberichten. Lade unsere Pressematerialien herunter oder fordere weitere Informationen an.',
    title: 'Presseraum',
  },
  news: {
    title: 'Unsere neuesten Nachrichten und Updates',
  },
  seo: {
    description:
      'Bleibe auf dem Laufenden mit Storyliner Nachrichten und Medienberichten und lade unsere Pressematerialien herunter. Mehr erfahren …',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Presseraum - Storyliner',
  },
};
