/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// early access thank you
export default {
  blog: {
    title:
      'Erfahre mehr über die <span class="has-marker">spannende</span> Zukunft der Inhaltserstellung',
  },
  hero: {
    intro:
      'Bevor du Zugang erhältst, musst du deine E-Mail-Adresse bestätigen. Bitte klicke auf den Link in der E-Mail, die wir dir gerade geschickt haben, um die Registrierung abzuschließen. Bei Fragen oder Unklarheiten, melde dich bitte bei unserem Support-Team: <a href="mailto:support@storylinerlabs.com">support@storylinerlabs.com</a>',
    title: 'Danke für deine Registrierung',
  },
  seo: {
    description:
      'Bevor wir du Zugang erhältst, müsst du deine E-Mail-Adresse bestätigen. Bitte klicke auf den Link in der E-Mail, die wir dir gerade geschickt haben, um die Registrierung abzuschließen.',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Danke für deine Registrierung - Storyliner',
  },
};
