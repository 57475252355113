// import translations
import components from './components';
import navigations from './navigations.json';

// create translations obj
const translations = {
  components,
  navigations,
} as const;

export default translations;
