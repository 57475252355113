// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { CookiesProvider } from 'react-cookie';

// import Providers
import AnalyticsProvider from './AnalyticsProvider';
import LocalizationProvider from './LocalizationProvider';
import NProgressProvider from './NProgressProvider';
import PasswordProtectionProvider from './PasswordProtectionProvider';
import SmoothScrollProvider from './SmoothScrollProvider';
import ToastProvider from './ToastProvider';
import YoutubePrivacyProvider from './YoutubePrivacyProvider';

// NOTE: order matters!

// AppProviders component
const AppProviders: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => (
  <CookiesProvider>
    <AnalyticsProvider>
      <LocalizationProvider>
        <ToastProvider>
          <NProgressProvider>
            <YoutubePrivacyProvider>
              <SmoothScrollProvider>
                <PasswordProtectionProvider>{children}</PasswordProtectionProvider>
              </SmoothScrollProvider>
            </YoutubePrivacyProvider>
          </NProgressProvider>
        </ToastProvider>
      </LocalizationProvider>
    </AnalyticsProvider>
  </CookiesProvider>
);

AppProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProviders;
