/* eslint-disable import/no-anonymous-default-export */

// import types
import { RouteEnum } from '../../../types';

// import config
import { storylinerRootDomain } from '../../../../config';

// help center
export default {
  hero: {
    intro:
      'Do you have questions about the product? Or need help with your content strategy? Our help center offers a first starting point for your questions.',
    title: 'We\'re <span class="has-marker">happy</span> to help.',
  },
  possibilities: {
    items: [
      {
        description:
          "Tell us about your problem via email, and we'll find you a solution as soon as possible.",
        icon: 'ri-customer-service-2-line',
        name: 'Contact support',
        link: {
          href: 'mailto:support@storylinerlabs.com',
          label: 'Send email',
        },
      },
      {
        description:
          'We have a directory of professional content creators across the globe that we can refer you to.',
        icon: 'ri-user-2-line',
        name: 'Hire an expert',
        link: {
          href: 'mailto:expert@storylinerlabs.com',
          label: 'Send inquiry',
        },
      },
      // {
      //   description:
      //     'Connect with other Storyliner users to get help crafting the perfect creative workflow.',
      //   icon: 'ri-slack-line',
      //   name: 'Ask community',
      //   link: {
      //     href: RouteEnum.WEBSITE_COMMUNITY_EN,
      //     label: 'Join community',
      //   },
      // },
      {
        description:
          'Follow our change log to stay informed about current updates that affect the Storyliner applications.',
        icon: 'ri-notification-badge-line',
        name: 'Check release notes',
        link: {
          href: RouteEnum.WEBSITE_RELEASE_NOTES_EN,
          label: 'See latest changes',
        },
      },
      {
        description: "Check the Storyliner docs. There's likely an article for your issue already.",
        icon: 'ri-file-search-line',
        name: 'Check the docs',
        link: {
          href: RouteEnum.WEBSITE_HELP_CENTER_EN,
          label: 'Coming soon…',
        },
      },
      {
        description:
          'Learn best practices and craft your creative workflow with the Storyliner in-depth tutorials.',
        icon: 'ri-treasure-map-line',
        name: 'Read tutorials',
        link: {
          href: RouteEnum.WEBSITE_HELP_CENTER_EN,
          label: 'Coming soon…',
        },
      },
    ],
  },
  seo: {
    description:
      'Do you have questions about the product? Our help center offers a first starting point for your questions.',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Help Center - Storyliner',
  },
};
