/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// genres
export default {
  genres: {
    dividerTopDark: 'divider-top-tuatuara-1',
    dividerTop: 'divider-top-desertstorm-1',
    title: 'Die Storyliner-Genres',
  },
  hero: {
    intro:
      'Bekomme mehr Vielfalt in deine Unternehmenskommunikation mit unseren Genres und Storylines. Während des Kreationsprozesses hast du die Möglichkeit auf verschiedene professionelle Darstellungsformen zurückzugreifen. Unsere Technologie passt jeden Typ anschließend individuell auf dein Inhaltsstück und deine Kommunikationsstrategie an.',
    title: '<span class="has-marker">Wow</span> statt Blabla',
  },
  innovationPartner: {
    callToAction: {
      label: 'Innovationspartner werden',
      link: 'mailto:innovation@storylinerlabs.com',
    },
    dividerBottomDark: 'divider-bottom-tawnyport-1',
    dividerBottom: 'divider-bottom-allports-1',
    intro:
      'Werde Innovationspartner und entwickle, wie auch andere Content-Kreative oder Kommunikationsteams, gemeinsam mit uns neue Darstellungsformen oder automatisiere das Erstellen bestehender Formate. Interesse? Schreib uns.',
    logos: [
      {
        alt: 'Logo Newsaktuell',
        src: 'storyliner/logos/logo-newsaktuell_fvixyr',
      },
      {
        alt: 'Logo Hyconnect',
        src: 'storyliner/logos/logo-hyconnect_vjgzt0',
      },
      {
        alt: 'Logo awork',
        src: 'storyliner/logos/logo-awork_y2bgaf',
      },
    ],
    title: 'Willst du dein eigenes Genre entwickeln?',
  },
  seo: {
    description:
      'Bekomme mehr Vielfalt in deine Unternehmenskommunikation mit unseren Genres und Storylines. Jetzt Genres durchstöbern …',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Genres - Storyliner',
  },
};
