/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// features
export default {
  features: [
    {
      description:
        'With Storyliner, you can not only document your developed content strategy, but also implement it directly. Using communication guidelines, we help you communicate strategically and consistently.',
      id: 'strategy',
      items: [
        {
          image: 'storyliner/website/storyliner-features-strategy-en_qkyknh',
          text: 'Put an end to the chaos of ideas and inconsistent content. Define your communication strategy and set goals, audiences, or language guidelines. You can categorize your content based on your information and receive recommendations in real-time.',
          title: 'Communication strategy',
        },
        {
          image: 'storyliner/website/storyliner-features-strategy-en_qkyknh',
          text: "A style guide is the foundation of communication for any brand. With Storyliner, you can capture language rules, the addressee, dictionaries, and writing standards, and we'll make sure you stick to them in different formats across all channels.",
          title: 'Styleguide and language rules',
        },
        // {
        //   image: 'storyliner-features-strategy-de.png',
        //   text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        //   title: 'Publikum und Personas (bald)',
        // },
        // {
        //   image: 'storyliner-features-strategy-de.png',
        //   text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        //   title: 'Keywords und Hashtags (bald)',
        // },
      ],
      label: 'Strategy',
      title: 'Where Strategy meets creation',
    },
    {
      description:
        'Conceive your content strategically. Use creative briefings to answer key conceptual questions about your story and improve team collaboration.',
      id: 'plan',
      items: [
        {
          image: 'storyliner/website/storyliner-features-plan-en_pphctf',
          text: 'Develop compelling content with creative briefings. In the briefing for a story, you clarify the goals and audiences and define the narrative structure. With the planning, you improve the exchange in the team and lay the foundation for the generated inspiration and content analyses.',
          title: 'Creative Briefings',
        },
        {
          image: 'storyliner/website/storyliner-features-plan-en_pphctf',
          text: 'Get more variety in your corporate communication with our professional genres. During the briefing, you can choose between different forms of presentation. On this basis, narrative structures are then generated for your communication channels.',
          title: 'Professional genres',
        },
        {
          image: 'storyliner/website/storyliner-features-plan-en_pphctf',
          text: "Speed up your creative process. Once you've developed a storyline, you can save it as a template for later and reuse it for the next story. Or save a complete briefing, created channels and storyline as a format. This way, you can further automate your creation process.",
          title: 'Recurring formats and storylines',
        },
        {
          image: 'storyliner/website/storyliner-features-plan-en_pphctf',
          text: 'Flexible and customizable for any workflow - Plan your editorial schedule using Kanban, List, or Gallery views. This makes idea and communication management transparent and easy for any content team.',
          title: 'Flexible management views',
        },
      ],
      label: 'Plan',
      title: 'Well planned is half created',
    },
    {
      description:
        "Put an end to the tedious copying back and forth between tools and countless places where content, thoughts, and notes are stored. Organize your creative chaos with Storyliner's research features.",
      id: 'research',
      items: [
        {
          image: 'storyliner/website/storyliner-features-research-en_qebhko',
          text: 'Collect, sort, and structure text, images, videos, tweets, or files for a single piece of content in one place. You can integrate the blocks directly into your storyline or document as you create.',
          title: 'Central block collection',
        },
        {
          image: 'storyliner/website/storyliner-features-research-en_qebhko',
          text: 'Collect images, text passages, bookmarks, and more with just one click directly while researching. With our Chrome browser extension or mobile apps (coming soon), you can send your found sources to Storyliner for further processing without any detours.',
          title: 'Clipping apps',
        },
        // {
        //   image: 'storyliner-features-research-de.png',
        //   text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        //   title: 'Quellenvalidierung (bald)',
        // },
        // {
        //   image: 'storyliner-features-research-de.png',
        //   text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        //   title: 'Inhaltsempfehlungen (bald)',
        // },
      ],
      label: 'Research',
      title: "Quickly grasp what's essential",
    },
    {
      description:
        'Staring at a blank sheet of paper for hours was yesterday. Today you start your content creation with structure, generated text modules and inspiration. Compose content for your entire corporate communication in no time.',
      id: 'create',
      items: [
        {
          image: 'storyliner/website/storyliner-features-create-en_utnl4p',
          text: 'Storyline first, channels second - Develop a concept once and deliver it to multiple communication channels. With our multi-document creative environment, you can clearly adapt your content for each communication channel, arrange it via drag-and-drop function or save it as recurring formats.',
          title: 'Multi-document creative environment',
        },
        {
          image: 'storyliner/website/storyliner-features-create-en_utnl4p',
          text: 'Just as your communication is made up of many small components to form a large ensemble, so too does our flexible, creative environment. You can highlight and structure content using concept blocks. Use the quick menu to load examples and inspiration, translate content automatically, insert saved blocks, or load media in seconds.',
          title: 'Concept blocks and quick menu',
        },
        {
          image: 'storyliner/website/storyliner-features-create-en_utnl4p',
          text: 'Boost your creative output with the help of artificial intelligence. Based on your briefing and your communication guidelines, you can have text blocks generated as inspiration and transfer them directly into your content. Using examples and templates, you can develop compelling content yourself.',
          title: 'Generated texts and templates',
        },
        {
          image: 'storyliner/website/storyliner-features-create-en_utnl4p',
          text: 'Finally, collaboration without chaos – with Storyliner, you can work together on the same document, get feedback directly on the content level. With the status indicator, there are no more annoying "is it ready yet" questions. The integrated version management saves everything automatically.',
          title: 'Collaboration features',
        },
      ],
      label: 'Create',
      title: 'Unleash your creativity',
    },
    {
      description:
        "Content creation is becoming more and more complex. That's why you get comprehensive and easy-to-understand analysis results. In this way, you turn good content into compelling stories.",
      id: 'optimize',
      items: [
        {
          image: 'storyliner/website/storyliner-features-optimize-en_vx1tuz',
          text: 'Get the most out of your content. Eliminate spelling mistakes, improve your punctuation and grammar, and ensure error-free content with comprehensive and easy-to-understand content analysis and tips.',
          title: 'Grammar and spelling',
        },
        {
          image: 'storyliner/website/storyliner-features-optimize-en_vx1tuz',
          text: 'Better expression and consistent content across channels - based on emotion analysis and the defined style guide, you will receive tips and suggestions for improvement on formality, address, and your personal dictionary.',
          title: 'Tonality and language rules',
        },
        // {
        //   image: 'storyliner-features-optimize-en.png',
        //   text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        //   title: 'Tipps und Heuristiken (bald)',
        // },
        // {
        //   image: 'storyliner-features-optimize-en.png',
        //   text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        //   title: 'Plagiatsprüfung (bald)',
        // },
      ],
      label: 'Optimize',
      title: 'Fine-tune your content',
    },
    {
      description:
        'Storyliner integrates seamlessly into your existing technology landscape. So you can easily download all your content or export it directly to the app of your choice.',
      id: 'publish',
      items: [
        {
          image: 'storyliner/website/storyliner-features-export-en_zcphat',
          text: 'Have you finished your content? Then you can download them in common file formats, such as PDF, DOCX, MD, HTML or TXT and prepared according to communication channel.',
          title: 'Document and media download',
        },
        {
          image: 'storyliner/website/storyliner-features-export-en_zcphat',
          text: 'Export your content directly to the software, tool, or content management system of your choice using our Zapier API. Through Zapier, you can connect Storyliner to the app of your choice. For example, with WordPress or Google Docs.',
          title: 'Export via Zapier',
        },
        // {
        //   image: 'storyliner-features-export-de.png',
        //   text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        //   title: 'Zeitgesteuertes Veröffentlichen (bald)',
        // },
        // {
        //   image: 'storyliner-features-export-de.png',
        //   text: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        //   title: 'Direkte Integrationen (bald)',
        // },
      ],
      label: 'Publish',
      title: 'Export anywhere you want',
    },
  ],
  hero: {
    intro:
      'Content creation, as you know, it is out the door. Storyliner is totally familiar but entirely different. Explore our features along the creative process.',
    title: 'We didn\'t reinvent the wheel, <span class="has-marker">only</span> content creation.',
  },
  seo: {
    description:
      'Content creation, as you know, it is out the door. Storyliner is totally familiar but entirely different. Discover our features along the creative process.',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Features - Storyliner',
  },
  testimonial: {
    bgColor: 'energyYellow',
    color: 'black',
    colorDark: 'white',
    image: 'storyliner/testimonials/testimonial-suheda-turedi_ecgwgx',
    jobTitle: 'Founder & Designer, suesmadewithlove.com',
    name: 'Suheda Türedi',
    quote:
      '„Since I have been using Storyliner, I save time when creating my content. A real must-have tool for all content marketers!“',
    snippet: 'snippet-yellow-1',
    snippetDark: 'snippet-tawnyport-1',
  },
};
