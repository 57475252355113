// content enums
export type ReleaseNoteAppType = 'CHROMEEXTENSION' | 'WEBAPP';

export enum ReleaseNoteAppTypeEnum {
  CHROMEEXTENSION = 'CHROMEEXTENSION',
  WEBAPP = 'WEBAPP',
}

export type ReleaseNoteCategory = 'IMPROVEMENTS' | 'FEATURES';

export enum ReleaseNoteCategoryEnum {
  IMPROVEMENTS = 'IMPROVEMENTS',
  FEATURES = 'FEATURES',
}
