/* eslint-disable import/no-anonymous-default-export */

// import types
import { RouteEnum } from '../../../types';

// import config
import { storylinerRootDomain } from '../../../../config';

// home
export default {
  audiences: {
    items: [
      {
        description:
          'Verwalte deine Kommunikationsrichtlinien übersichtlich an einem Ort. Plane deine Redaktion und automatisiere deine Inhaltserstellung mittels definierten Formaten und standardisierten Kreativ-Briefings.',
        icon: 'ri-pie-chart-box-line',
        name: 'Content Strategen',
      },
      {
        description:
          'Kein Ping-Pong mehr zwischen Dokumenten, Freigabeprozessen und Nischen-Workflow-Apps: Mit Storyliner konzipierst du Inhalte zentral in einer flexiblen Kreativumgebung.',
        icon: 'ri-pencil-ruler-2-line',
        name: 'Kreative',
      },
      {
        description:
          'Überführe dein Wissen in überzeugende Inhalte mittels generierten Erzählstrukturen, professionellen Genres und inspirierenden Beispielen. Entwickle in kurzer Zeit Inhalte für all deine Kommunikationsanlässe.',
        icon: 'ri-lightbulb-line',
        name: 'Unternehmer',
      },
      {
        description:
          'Inhaltskreation wird besser im Team. Arbeite gemeinsam mit anderen an Konzepten, evaluiere Inhalte oder erhalte in Echtzeit Feedback zu Überschriften oder anderen Inhaltsbausteinen.',
        icon: 'ri-group-2-line',
        name: 'Content Teams',
      },
      // {
      //   description:
      //     'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
      //   icon: 'ri-newspaper-line',
      //   name: 'PR Spezialisten',
      // },
      // {
      //   description:
      //     'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
      //   icon: 'ri-search-eye-line',
      //   name: 'Journalisten',
      // },
      {
        description:
          'Entwickle deine Storys einmalig und adaptiere sie für verschiedene Kommunikationskanäle. Optimiere und veröffentliche deine Inhalte direkt auf Social-Media-Plattformen mit nur wenigen Klicks.',
        icon: 'ri-instagram-line',
        name: 'Social Media Manager',
      },
      {
        description:
          'Schluss mit eintönigen Texten. Entwerfe mühelos neue Blogartikel anhand generierter Genres und eigens entwickelten Formaten. Optimiere deine Beiträge für Suchmaschinen anhand Tipps und Inhaltsanalysen.',
        icon: 'ri-draft-line',
        name: 'Werbetexter',
      },
      // {
      //   description:
      //     'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
      //   icon: 'ri-mail-send-line',
      //   name: 'Agenturen',
      // },
    ],
    callToAction: {
      label: 'Alle Funktionen entdecken',
      href: RouteEnum.WEBSITE_FEATURES_DE,
    },
    title: 'Für alle, die etwas zu sagen haben.',
  },
  benefits: {
    items: [
      {
        description:
          'Organisiere dein kreatives Chaos, strukturiere Ideen oder speichere Notizen mit den smarten Inhaltsblöcken von Storyliner. Mit professionellen Storylines - den Storyliner-Genres - verwandelst du lose Inhalte im Handumdrehen in überzeugende Storys.',
        headline: 'Konzipiere Inhalte schneller mit smarten Inhaltsblöcken.',
        image: 'storyliner/website/storyliner-benefit-smart-blocks_qp8eoc',
      },
      {
        description:
          'Erhalte generierte Texte, Inspiration, automatisierte Übersetzungen und Empfehlungen, basierend auf deiner Strategie. Optimiere deine Inhalte entlang des kreativen Erstellungsprozesses: für jeden Kommunikationsanlass und jeden Kommunikationskanal.',
        headline: 'Kreativitätssteigerung, von der Idee bis zur Veröffentlichung.',
        image: 'storyliner/website/storyliner-benefit-creative-enhancement_na0kiv',
      },
      {
        description:
          'Kein Pingpong mehr zwischen Dokumenten und Nischen-Workflow-Apps, um Dinge zu erledigen. Storyliner bringt alle deine Inhalte, Daten und Teammitglieder in einer produktiven Kreativumgebung zusammen, um deinen kreativen Output zu steigern.',
        headline: 'Kreative Workflows, die Daten und Teams zusammenbringen.',
        image: 'storyliner/website/storyliner-benefit-collaboration_gc5qkz',
      },
    ],
    callToAction: {
      label: 'Wie es funktioniert',
      href: RouteEnum.WEBSITE_HOWITWORKS_DE,
    },
    title: '<span class="has-marker">Ein Ort</span> für kreative Exzellenz',
  },
  blog: {
    title:
      'Erfahre mehr über die <span class="has-marker">spannende</span> Zukunft der Inhaltserstellung',
  },
  genres: {
    callToAction: {
      label: 'Genres entdecken',
      href: RouteEnum.WEBSITE_GENRES_DE,
    },
    dividerBottom: 'divider-bottom-desertstorm-1',
    dividerBottomDark: 'divider-bottom-tuatuara-1',
    intro:
      'Lass dich von unseren generierten professionellen Darstellungsformen, den Storyliner Genres, inspirieren und automatisiere deine Inhaltskreation.',
    title: 'Deine Idee, vielfältige Storylines.',
  },
  hero: {
    intro:
      'Mit Storyliner <strong>konzipieren Kreative und Content-Teams</strong> Kommunikation strategisch auf <strong>einer Plattform</strong>. Durch smarte Inhaltsblöcke, Inspiration und ständigem Feedback zur Qualität, entwickelst du <strong>Konzepte in Minuten.</strong>',
    title:
      '<span class="has-marker">Überzeugende</span> Inhalte. Inspiriert durch KI, gemeinsam erstellt.',
  },
  press: {
    dividerBottom: 'divider-bottom-tuatuara-1',
    logos: [
      {
        alt: 'Logo Börsenblatt',
        src: 'storyliner/logos/logo-boersenblatt_j0bdwu',
      },
      {
        alt: 'Logo Hamburg Startups',
        src: 'storyliner/logos/logo-hamburg-startups_cf8av0',
      },
      {
        alt: 'Logo Werben und Verkaufen',
        src: 'storyliner/logos/logo-wundv_e4fggp',
      },
      {
        alt: 'Logo Junge Verlagsmenschen',
        src: 'storyliner/logos/logo-junge-verlagsmenschen_ez0jsy',
      },
    ],
    title: 'Bekannt aus',
  },
  productPreview: {
    videoPoster: 'storyliner/website/storyliner-product-demo_fd5vl2',
    videoUrl: 'https://www.youtube-nocookie.com/embed/L-NsBFPy_2U',
  },
  seo: {
    description:
      'Mit smarten Blöcken, Inspiration und Inhaltsanalysen entwickelst du überzeugende Inhalte in Minuten. Jetzt anmelden!',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Storyliner – die KI-gestützte Kreativplattform',
  },
  testimonial: {
    bgColor: 'allports',
    color: 'white',
    colorDark: 'white',
    image: 'storyliner/testimonials/testimonial-daniela-keller_yxwx3t',
    jobTitle: 'Statistik-Expertin & Bloggerin, statistik-und-beratung.de',
    name: 'Daniela Keller',
    quote:
      '„Mit den vielen Format-Ideen und Vorschlägen von Storyliner bekomme ich mehr Abwechslung in meine Artikel.“',
    snippet: 'snippet-anakiwa-1',
    snippetDark: 'snippet-tawnyport-1',
  },
};
