/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// early access confirmed
export default {
  blog: {
    title:
      'Learn more about the <span class="has-marker">exciting</span> future of content creation',
  },
  hero: {
    title: 'Registration confirmed',
    intro:
      "You have successfully registered for our private beta version. We'll get back to you soon with all the information you need to create compelling content.",
  },
  seo: {
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Registration confirmed - Storyliner',
    description:
      "You have successfully registered for our private beta. We'll get back to you soon with all the information you need to create compelling content.",
  },
};
