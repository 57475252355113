// config

// Cloudinary
export const cloudinaryName = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME;

// Cookies
export const cookiesBaseDomain = process.env.NEXT_PUBLIC_COOKIES_BASE_DOMAIN;

// DatoCMS
export const datoCmsWebsiteApiToken = process.env.DATOCMS_WEBSITE_API_TOKEN;

// Google Tag Manager
export const tagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

// Storyliner
export const storylinerApiKey = process.env.NEXT_PUBLIC_STORYLINER_API_TOKEN_SECRET;

export const storylinerWebAppDomain = process.env.NEXT_PUBLIC_STORYLINER_WEB_APP_DOMAIN;

export const storylinerRootDomain = process.env.NEXT_PUBLIC_STORYLINER_ROOT_DOMAIN;

export const storylinerPasswordProtection = process.env.NEXT_PUBLIC_STORYLINER_PASSWORD_PROTECTED;
