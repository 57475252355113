/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// release notes
export default {
  hero: {
    intro:
      'Folge unserem Changelog, um über aktuelle Updates, die die Storyliner-Applikationen und deinen Arbeitsbereich betreffen, informiert zu bleiben.',
    title: 'Changelog',
  },
  seo: {
    description:
      'Folge unserem Changelog, um über aktuelle Updates, die die Storyliner-Applikationen und deinen Arbeitsbereich betreffen, informiert zu bleiben. Jetzt lesen.',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Changelog - Storyliner',
  },
};
