// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { I18nextProvider } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { useImmerAtom } from 'jotai/immer';
import { useRouter } from 'next/router';

// import modules
import { i18n } from '../../modules/i18n';
import { localeAtom } from '../../modules/jotai';

// import config
import { cookiesBaseDomain } from '../../../config';

// LocalizationProvider component
const LocalizationProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({
  children,
}) => {
  // init hooks
  const [, setLocale] = useImmerAtom(localeAtom);
  const [, setCookie] = useCookies(['locale']);
  const router = useRouter();

  // NOTE: this is because of next export
  const nextLocale = React.useMemo(
    () => (router?.asPath?.startsWith('/de') ? 'de' : 'en'),
    [router?.asPath],
  );

  // NOTE: this is due to hydration
  if (i18n.language !== nextLocale) {
    i18n.changeLanguage(nextLocale || 'en');
  }

  // handle data changes
  React.useEffect(() => {
    // set i18n language
    i18n.changeLanguage(nextLocale || 'en');
    setCookie('locale', nextLocale, {
      path: '/',
      domain: cookiesBaseDomain,
      sameSite: 'lax',
      secure: process.env.VERCEL === '1',
    });
    setLocale(() => nextLocale || 'en');

    // NOTE: to update html lang tag
    // https://github.com/vercel/next.js/issues/9160#issuecomment-643867740
    document.documentElement.lang = nextLocale;
  }, [nextLocale, setCookie, setLocale]);

  // render app
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

LocalizationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LocalizationProvider;
