/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// 404
export default {
  seo: {
    description: 'Die Seite, die du suchst, existiert nicht oder wurde entfernt bzw. verschoben.',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Seite nicht gefunden - Storyliner',
  },
  hero: {
    title: 'Seite nicht gefunden',
    description: 'Die Seite, die du suchst, existiert nicht oder wurde entfernt bzw. verschoben.',
    link: {
      label: 'Zur Startseite',
    },
  },
};
