/* eslint-disable import/no-anonymous-default-export */

// import types
import { RouteEnum, PriceBoxColorEnum } from '../../../types';

// import config
import { storylinerRootDomain } from '../../../../config';

// components
export default {
  ClickToTweet: {
    link: {
      label: 'Click to tweet',
    },
  },
  BlogPost: {
    readMore: {
      label: 'More resources',
    },
  },
  EarlyAccessForm: {
    email: {
      placeholder: 'don@sterling-draper.com',
      error: 'Please enter a valid email address.',
    },
    privacy: {
      text: `We respect your <a href="${storylinerRootDomain}${RouteEnum.WEBSITE_PRIVACY_EN}">privacy</a>.`,
      tooltip:
        'For information on logging your registration, sending emails via ConvertKit and AWS, statistical analysis and unsubscribe options, please see our privacy policy.',
    },
    submit: {
      label: 'Get access',
      error:
        'An error occurred while registering. Please contact our support team: support@storylinerlabs.com',
    },
  },
  EarlyAccessModal: {
    text: 'Get access to your creative platform and product updates and turn loose ideas into compelling stories. We are a small dedicated team with big plans and an even bigger waitlist. So we ask you for patience in advance…',
    title:
      'Storyliner is currently available in an <span class="has-marker">exclusive</span> beta version.',
  },
  EarlyAccessSection: {
    callToAction: {
      label: 'Start creating',
    },
    creditCard: 'Free trial. No credit card required.',
    intro: 'Streamline your creative workflows and start your free trial.',
    title: 'Content creation has never been <span class="has-marker">more productive</span>',
  },
  EmbedOptin: {
    Youtube: {
      buttonText: 'Load Youtube video',
      infoText:
        'When you load the video, you agree that your data will be transmitted to Youtube. You can find further information in our <a href="https://storylinerlabs.com/privacy/">Privacy Policy</a>.',
    },
  },
  GetDemoSection: {
    callToAction: {
      label: 'Request a demo',
      href: 'mailto:demo@storylinerlabs.com',
    },
    tour: 'Get a personalized tour',
    intro:
      'When content powers your business, choosing a new tool is a big deal. Join one of our experts on a live tour and get the answers you need.',
    title: 'Want to see Storyliner in <span class="has-marker">action</span>?',
  },
  ImageTabs: {
    soon: {
      tooltip: 'Soon',
    },
  },
  ProductUpdatesForm: {
    email: {
      placeholder: 'don@sterling-draper.com',
      error: 'Please enter a valid email address.',
    },
    privacy: {
      text: `We respect your <a href="${storylinerRootDomain}${RouteEnum.WEBSITE_PRIVACY_EN}">privacy</a>.`,
      tooltip:
        'For information on logging your registration, sending emails via ConvertKit and AWS, statistical analysis and unsubscribe options, please see our privacy policy.',
    },
    submit: {
      label: 'Subscribe',
      error:
        'An error occurred while registering. Please contact our support team: support@storylinerlabs.com',
    },
  },
  NewsletterForm: {
    email: {
      placeholder: 'don@sterling-draper.com',
      error: 'Please enter a valid email address.',
    },
    privacy: {
      text: `We respect your <a href="${storylinerRootDomain}${RouteEnum.WEBSITE_PRIVACY_EN}">privacy</a>.`,
      tooltip:
        'For information on logging your registration, sending emails via ConvertKit and AWS, statistical analysis and unsubscribe options, please see our privacy policy.',
    },
    submit: {
      label: 'Subscribe',
      error:
        'An error occurred while registering. Please contact our support team: support@storylinerlabs.com',
    },
  },
  NewsletterSection: {
    intro:
      'Subscribe to our newsletter and receive regular updates and inspiration, unique perspectives, and analysis on the future of content creation.',
    title:
      '<span class="has-marker">Don\'t miss</span> an update on the future of content creation.',
  },
  PricingTable: {
    billingCycle: {
      annually: 'Yearly',
      monthly: 'Monthly',
    },
    chooseLater:
      'Signup for a trial first. Once you\'ve subscribed, you\'ll be able upgrade to a higher processing tier in the "Billing" section of your Workspace.',
    creativeEnhancement: {
      text: 'of Creative Enhancement',
      tooltip:
        'We include under the term "Creative Enhancement" the use of our AI features such as "Text Generation", "Content Analytics" or "Machine Translation".',
    },
    currency: {
      dollar: 'USD',
      euro: 'EUR',
    },
    discount: {
      annually: 'Get ~2 month off with annual billing',
      monthly: 'Switch to annual billing and save 2 month',
    },
    mostPopular: 'Most popular',
    plans: [
      {
        description:
          'For content creators who want to take their creative workflows to the next level and increase their content quality and quantity.',
        features: [
          {
            id: 'unlimited-blocks',
            name: '<strong>Unlimited creation of content</strong>',
            soon: false,
          },
          {
            id: 'members',
            name: '<strong>Up to 2 editors</strong>, unlimited viewers',
            soon: false,
          },
          {
            id: 'language',
            name: '1 content language',
            soon: false,
          },
          {
            id: 'creative-features',
            name: 'All creative features',
            soon: false,
          },
        ],
        id: 'creator',
        name: 'Creator',
        priceBox: {
          color: PriceBoxColorEnum.yellow,
          price: {
            annually: {
              description: 'per workspace/month – billed annually',
              dollar: '$29',
              euro: '€24',
            },
            callToAction: 'Start free 14 days trial',
            monthly: {
              description: 'per workspace/month – billed monthly',
              dollar: '$35',
              euro: '€29',
            },
          },
        },
        runs: [
          {
            annually: {
              dollar: '$29',
              euro: '€24',
            },
            id: 'creator-500',
            label: '500 runs per month',
            monthly: {
              dollar: '$35',
              euro: '€29',
            },
          },
          {
            annually: {
              dollar: '$59',
              euro: '€49',
            },
            id: 'creator-1000',
            label: '1,000 runs per month',
            monthly: {
              dollar: '$69',
              euro: '€59',
            },
          },
        ],
      },
      {
        description:
          'For in-house teams with a growing demand for quality content, who want to master collaboration and boost their performance.',
        features: [
          {
            id: 'creator-plan',
            name: '<strong>Everything in Creator plan</strong>, plus',
            soon: false,
          },
          {
            id: 'members',
            name: '<strong>Unlimited editors</strong>, unlimited viewers',
            soon: false,
          },
          {
            id: 'languages',
            name: '2 content languages',
            soon: true,
          },
          {
            id: 'integrations',
            name: 'Export integrations',
            soon: true,
          },
        ],
        id: 'team',
        name: 'Team',
        priceBox: {
          color: PriceBoxColorEnum.anakiwa,
          price: {
            annually: {
              description: 'per workspace/month – billed annually',
              dollar: '$89',
              euro: '€79',
            },
            callToAction: 'Start free 14 days trial',
            monthly: {
              description: 'per workspace/month – billed monthly',
              dollar: '$109',
              euro: '€99',
            },
          },
        },
        runs: [
          {
            annually: {
              dollar: '$89',
              euro: '€79',
            },
            id: 'team-2000',
            label: '2,000 runs per month',
            monthly: {
              dollar: '$109',
              euro: '€99',
            },
          },
          {
            annually: {
              dollar: '$229',
              euro: '€209',
            },
            id: 'team-5000',
            label: '5,000 runs per month',
            monthly: {
              dollar: '$279',
              euro: '€249',
            },
          },
          {
            annually: {
              dollar: '$469',
              euro: '€419',
            },
            id: 'team-10000',
            label: '10,000 runs per month',
            monthly: {
              dollar: '$569',
              euro: '€499',
            },
          },
        ],
      },
      {
        description:
          'For larger organizations who want to scale their content operation to different languages and need advanced security.',
        features: [
          {
            id: 'team-plan',
            name: '<strong>Everything in Team plan</strong>, plus',
            soon: false,
          },
          {
            id: 'integrations',
            name: '<strong>All available languages and integrations</strong>',
            soon: false,
          },
          {
            id: 'security',
            name: 'SAML-based SSO',
            soon: false,
          },
          {
            id: 'support',
            name: 'Enterprise support',
            soon: false,
          },
        ],
        id: 'enterprise',
        name: 'Enterprise',
        priceBox: {
          color: PriceBoxColorEnum.desertStorm,
          price: {
            annually: {
              description: 'billed annually',
              dollar: 'Custom',
              euro: 'Custom',
            },
            callToAction: 'Get in touch',
            monthly: {
              description: 'billed annually',
              dollar: 'Custom',
              euro: 'Custom',
            },
          },
        },
        runs: [
          {
            annually: {
              dollar: 'Custom',
              euro: 'Custom',
            },
            id: 'enterprise-custom',
            label: 'Custom runs per month',
            monthly: {
              dollar: 'Custom',
              euro: 'Custom',
            },
          },
        ],
      },
    ],
    specialPlans:
      'We also have special plans for nonprofit and early-stage startups. <a href="mailto:sales@storylinerlabs.com">Contact sales</a>.',
    soon: 'Soon',
  },
  PrivacyActions: {
    alerts: {
      facebookPixel: 'You have successfully unsubscribed from Facebook pixel tracking.',
      googleAnalytics: 'You have successfully unsubscribed from Google Analytics.',
    },
  },
  ReleaseNotes: {
    app: {
      WEBAPP: 'WebApp',
      CHROMEEXTENSION: 'ChromeExtension',
    },
    category: {
      FEATURES: 'NewFeatures',
      IMPROVEMENTS: 'Improvements',
    },
  },
  ThemeSwitcher: {
    dark: 'Switch to Light Mode',
    light: 'Switch to Dark Mode',
  },
};
