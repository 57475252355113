/* eslint-disable import/no-anonymous-default-export */

// import types
import { RouteEnum } from '../../../types';

// import config
import { storylinerRootDomain } from '../../../../config';

// how it works
export default {
  buildingBlocks: {
    items: [
      {
        image: 'storyliner/svgs/storyliner-block_f3nobb',
        text: 'Blocks are like individual scenes that together tell a story. You can use Blocks to develop your idea into a concept. You can format passages, arrange text, insert images or inspiration, and create the perfect workflow for your content operation.',
        title: 'Block',
      },
      {
        image: 'storyliner/svgs/storyliner-storyline_weiv21',
        text: 'A Storyline defines the narrative structure of your Story. In Storyliner, you can string Blocks together in a logical order to make the entire Story easy to understand and follow and adapt it more efficiently for different communication channels.',
        title: 'Storyline',
      },
      {
        image: 'storyliner/svgs/storyliner-document_let6u7',
        text: 'The Storyline is used to derive a Document containing the final content for a specific communication occasion, communication channel, or content format. This way, you can easily create multiple Documents from one Storyline.',
        title: 'Document',
      },
      {
        image: 'storyliner/svgs/storyliner-story_xzb6lt',
        text: "With Storyliner, you create compelling content – or in other terms, Stories. A Story holds everything together: the creative brief, the documents for each channel, the team's responsibilities, the editorial status, and the Storyline.",
        title: 'Story',
      },
    ],
    title: 'How Storyliner helps you create',
  },
  creativeEnhancement: {
    callToAction: {
      href: RouteEnum.WEBSITE_PRICING_EN,
      label: 'See pricing',
    },
    tabs: [
      {
        image: {
          alt: 'Text generation',
          src: 'storyliner/svgs/storyliner-creative-enhancement-text-generation_kvs0xu',
        },
        isDisabled: false,
        label: 'Text generation',
        text: 'Our language model generates text suggestions based on your creative briefing for headlines, introductions, etc., which you can directly adopt as inspiration.',
      },
      {
        image: {
          alt: 'Content analytics',
          src: 'storyliner/svgs/storyliner-creative-enhancement-content-analytics_jvqzma',
        },
        isDisabled: false,
        label: 'Content analytics',
        text: 'During the creation process, you will be supported by NLP algorithms that will check and improve your spelling, grammar, or speech quality. The analyses are individual and based on your established communication guidelines.',
      },
      {
        image: {
          alt: 'Maschine translation',
          src: '',
        },
        isDisabled: true,
        label: 'Maschine translation',
        text: 'Soon available…',
      },
    ],
    text: "Built on top of the world's most advanced AI language models, Storyliner helps you to boost your creativity while increasing your quality and productivity.",
    title:
      'Enhance your <span class="has-marker">creative output</span> with the power of Artificial Intelligence',
  },
  enrich: {
    tabs: [
      {
        image: {
          alt: 'Structure, and compose your Stories with ease and our Concept Blocks.',
          src: 'storyliner/svgs/storyliner-enrich-concept-blocks_txptvv',
        },
        label: 'Concept Blocks',
      },
      {
        image: {
          alt: 'Inspiration at your fingertips – with AI-generated texts or professional copy templates.',
          src: 'storyliner/svgs/storyliner-enrich-inspiration_prlt3b',
        },
        label: 'Inspiration',
      },
      {
        image: {
          alt: 'Save content blocks from anywhere – build Stories block by block.',
          src: 'storyliner/svgs/storyliner-enrich-saved-blocks_ogulyz',
        },
        label: 'Saved Blocks',
      },
      {
        image: {
          alt: 'Maximize your creation experience by using embeds, media imports and content integrations.',
          src: 'storyliner/svgs/storyliner-enrich-partner-integrations_lqx3sw',
        },
        label: 'Partner Integrations',
      },
    ],
    title:
      '<span class="has-marker">Enrich</span> your Stories with smart blocks, saved content, and our partner integrations.',
  },
  hero: {
    image: 'storyliner/website/storyliner-how-it-works-cover_uxgtox',
    intro:
      'For years, content creation was a Sisyphean task. Not anymore — we fixed it. Storyliner’s fresh approach transforms content creation into something you want to do instead of micro-managing tools, a constant lacking of ideas, and endless copying and pasting.',
    title: 'Don\'t be a Sisyphus, be a <span class="has-marker">Storyliner</span>.',
  },
  seo: {
    description:
      'We believe content creation should be easy because everyone deserves to create smarter — not harder. See how Storyliner works …',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'How it works - Storyliner',
  },
  steps: {
    items: [
      {
        image: 'storyliner/website/storyliner-how-it-works-step-1-genre_xjtutc',
        text: 'Start with a Genre – a predefined professional presentation form. You can also start with a self-developed Storyline or from scratch.',
        title: '#1 – Choose a Genre',
      },
      {
        image: 'storyliner/website/storyliner-how-it-works-step-2-briefing_kgm74x',
        text: 'In the briefing, you plan your Story. You define the audience, the objective, and the concept. It is the basis for machine-generated inspiration.',
        title: '#2 – Fill the Briefing',
      },
      {
        image: 'storyliner/website/storyliner-how-it-works-step-3-storyline_q4tdlw',
        text: 'The Storyline represents the foundation of the Story in terms of content. You develop it once and can then quickly adapt it for the individual communication channels and occasions.',
        title: '#3 – Compose your Storyline',
      },
      {
        image: 'storyliner/website/storyliner-how-it-works-step-4-document_hmhbtf',
        text: 'Fine-tune content for each communication channel. Get machine-generated inspiration, examples, and content analysis and feedback from your content team.',
        title: '#4 – Complete the Document',
      },
      {
        image: 'storyliner/website/storyliner-how-it-works-step-5-download_xnivsg',
        text: 'Publish the finished Story to the platform of your choice. You can download each document individually or publish it directly via the Zapier integration.',
        title: '#5 – Publish your Story',
      },
    ],
    title:
      'Create a concept <span class="has-marker">in minutes</span>, publish high-quality content.',
  },
  testimonial: {
    bgColor: 'anakiwa',
    color: 'black',
    colorDark: 'white',
    image: 'storyliner/testimonials/testimonial-dominic-strobel_vbg9le',
    jobTitle: 'Managing Director, Strobel & Walter GmbH',
    name: 'Dominic Strobel',
    quote:
      '„With Storyliner, we managed to get structure into our editorial process for Instagram. Based on defined Storylines, we have developed a series of recurring formats that saves us a lot of time in content creation.“',
    snippet: 'snippet-anakiwa-1',
    snippetDark: 'snippet-tawnyport-1',
  },
};
