/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// early access confirmed
export default {
  blog: {
    title:
      'Erfahre mehr über die <span class="has-marker">spannende</span> Zukunft der Inhaltserstellung',
  },
  hero: {
    title: 'Registrierung bestätigt',
    intro:
      'Du hast dich erfolgreich für unsere private Beta registriert. Wir werden uns in Kürze mit allen Informationen melden, die du benötigst, um überzeugende Inhalte zu erstellen.',
  },
  seo: {
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Registrierung bestätigt - Storyliner',
    description:
      'Du hast dich erfolgreich für unsere private Beta registriert. Wir werden uns in Kürze mit allen Informationen melden, die du benötigst, um überzeugende Inhalte zu erstellen.',
  },
};
