/* eslint-disable react/jsx-props-no-spreading */

// import node_modules
import * as React from 'react';
import PropTypes from 'prop-types';
import { RemixIcon } from '@storylinerlabs/design';
import { Slide, ToastContainer } from 'react-toastify';

// custom component
// NOTE: https://github.com/fkhadra/react-toastify/issues/224
const CloseIcon: React.FunctionComponent = () => <RemixIcon iconName="ri-close-line" />;

// ToastProvider component
const ToastProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => (
  <>
    {children}

    <ToastContainer
      autoClose={4000}
      closeButton={<CloseIcon />}
      closeOnClick
      draggable
      hideProgressBar
      icon={false}
      newestOnTop
      pauseOnFocusLoss
      pauseOnHover
      rtl={false}
      theme="colored"
      transition={Slide}
    />
  </>
);

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ToastProvider;
