/* eslint-disable import/no-anonymous-default-export */

// import types
import { RouteEnum } from '../../../types';

// import config
import { storylinerRootDomain } from '../../../../config';

// pricing
export default {
  hero: {
    title: '<span class="has-marker">Entfessle</span> dein kreatives Potenzial',
    intro: 'Wähle einen Plan, der zu dir und deinem Content-Projekt passt.',
  },
  faq: {
    questions: [
      {
        title: 'Was ist ein Workspace?',
        text: 'Dein Nutzerkonto in Storyliner kommt mit einem Workspace, welcher die Kommunikationsrichtlinien, Blöcke oder Storys deines Content-Projekts enthält. Du kannst in einem Workspace mit dem Rest deines Teams zusammenarbeiten, indem du Inhalte gemeinsam ansiehst, teilst oder bearbeitest – abhängig von der Rolle des jeweiligen Teammitglieds. Du kannst mit deinem Nutzerkonto mehreren Workspaces beitreten.',
      },
      {
        title: 'Welche Nutzerrollen gibt es?',
        text: 'Ein Storyliner-Workspace hat vier Nutzerrollen: Eigentümer, Administrator, Creator (Bearbeiter) oder Coworker. Eigentümer und Administratoren können Workspace-Mitglieder und die Abrechnung verwalten und haben alle Rechte eines Creator. Creator können Inhalte erstellen, Inspiration erhalten sowie Inhalte optimieren und übersetzen. Coworker haben nur Zugriff auf freigegebene Inhalte. Diese können sie entweder nur ansehen, kommentieren oder bearbeiten.',
      },
      {
        title: 'Was ist unter Kreativitätssteigerung zu verstehen?',
        text: 'Unter dem Begriff "Kreativitätssteigerung" fassen wir die Nutzung unserer KI-Funktionen wie "Text-Generierung", "Inhaltsanalyse" oder "Maschinen-Übersetzung" zusammen. Diese Funktionen sollen dich inspirieren, dir schnelles Feedback zur Qualität geben oder dir helfen bessere Texte zu formulieren – also deine Kreativität steigern.',
      },
      {
        title: 'Was passiert, wenn ich mein Limit erreicht habe?',
        text: 'Wenn du dein Durchläufe aufgebraucht hast und dein monatliches Limit an Kreativitätssteigerung erreicht hast, kannst du entweder upgraden oder bis zum nächsten Monat warten. Dir wird also nicht automatisch mehr berechnet. Nicht aufgebrauchte Durchläufe verfallen und werden nicht in den nächsten Monat übertragen.',
      },
      {
        title: 'Was bedeutet eine unbegrenzte Anzahl von Inhalten?',
        text: 'Du kannst mit jedem Tarifplan unbegrenzt viele Blöcke, Dokumente, Storys oder Storylines anlegen. Du kannst also vier Blogartikel im Monat schreibst oder 100+. Du kannst unbegrenzt viele Dokumente pro Story haben, Blöcke nach belieben sammeln oder Storylines nach Bedarf für später speichern. Wir berechnen dir nur mehr, für mehr Durchläufe an Kreativitätssteigerung und nicht für das Erstellen und Bearbeiten von Inhalten.',
      },
      {
        title: 'Was beinhalten die Kreationsfunktionen?',
        text: 'Jeder Workspace und Tarif kann auf alle Kreationsfunktionen zugreifen. Diese bestehen aus dem Verwalten der Kommunikationsrichtlinien – wie Publikum, Zielsetzung oder Sprachregeln – den Kreativbriefings, den Storyliner Genres, der Block-Verwaltung, dem Multi-Dokumenten-Editor mit all seinem Formatierungs- und Kollaborationsfunktionen, Datei- und Medienupload, sowie den Download- und Exportfunktionen.',
      },
      {
        title: 'Unterliegen die generierten Inhalten dem Urheberrecht?',
        text: 'Die maschinen-generierten Textbausteine unterliegen nicht dem Urheberrecht. Der Großteil der Rechtsprechungen weltweit (u.a. Deutschland, Europa, USA) erkennt eine Maschine als Schöpfer nicht an. Dies gilt trotz des orginellem Charakaters der generierten Textbausteine. Somit kannst du die Vorschläge direkt in deine Texte übernehmen, ohne sie abzuändern.',
      },
      {
        title: 'Was sind Inhaltssprachen?',
        text: 'Die Storyliner-Applikationen stehen in Englisch und Deutsch zur Verfügung. Für diese Sprachen erhältst du auch Beispiele und generierte Inspiration. Deine Inhalte können hingegen in verschiedenen Sprachen und verschiedenen Gebietsschemata, wie Britisches Englisch oder Brasilianische Portugiesisch übersetzt und bearbeitet werden.',
      },
      {
        title: 'Welche Export-Integrationen gibt es?',
        text: 'Du kannst deine Inhalte neben dem Download als PDF-, TXT-, DOCX- oder MD-Format auch via Zapier-Integration exportieren. Mit Zapier kannst du deine Arbeitsprozesse automatisieren und zwei Tools ohne Entwickleruntersützung miteinander verbinden – zum Beispiel Storyliner mit WordPress. Neben Zapier arbeiten wir an weiteren Integrationen, um Inhalte direkt an dein Content-Management-System oder Social-Media-Profil zu exportieren.',
      },
      {
        title: 'Wie funktionieren die Abonnements?',
        text: 'Du schließt in Storyliner Abonnements pro Workspace ab. Die  Abonnements verlängern sich automatisch am Ende eines jeden Abrechnungszeitraums (entweder monatlich oder jährlich) und gelten für einen einzelnen Workspace. Mit deinem Nutzerkonto kannst du zwischen mehreren Workspaces mit aktiven Abonnement hin- und herwechseln.',
      },
      {
        title: 'Welche Kündigungsfristen gibt es?',
        text: 'Gar keine! Du kannst Storyliner jeden Monat bzw. jedes Jahr ohne Angaben von Gründen kündigen. Schreibe einfach eine Nachricht an unseren Support. Das reicht aus. Wenn du nicht zufrieden bist, sind wir es auch nicht.',
      },
    ],
    intro: `Deine Frage ist nicht dabei? Du findest weitere Hilfe und Antworten in unserem <a href="${storylinerRootDomain}${RouteEnum.WEBSITE_HELP_CENTER_DE}">Help-Center</a>.`,
    title: 'Häufig gestellte Fragen',
  },
  pricing: {
    dividerBottomDark: 'divider-bottom-tuatuara-1',
    dividerBottom: 'divider-bottom-desertstorm-1',
    securityItems: [
      {
        id: 'gdpr',
        label: 'DSGVO-konform',
        icon: 'ri-shield-check-line',
      },
      {
        id: 'ssl',
        label: 'SSL-verschlüsselt',
        icon: 'ri-lock-password-line',
      },
    ],
  },
  runsExplained: {
    cards: [
      {
        title: '6',
        description: 'KI-generierte Kurzform-Texte wie Überschriften oder Call-to-Actions',
      },
      {
        title: '3',
        description: 'KI-generierte Langform-Texte wie Einleitungen oder Zusammenfassungen',
      },
      {
        title: '1x',
        description: 'Inhaltsanalyse, wie Rechtschreib- oder Grammatikprüfung',
      },
      {
        title: '1.000',
        description: 'Zeichen Maschinen-übersetzter Text in den verfügbaren Sprachen',
      },
    ],
    infoText:
      'Nutzer des Creator-Plan erstellen mit 500 Durchläufen beispielsweise 4 Blogartikel und 30 Beiträge für Facebook und LinkedIn und einige Marketingtexte. Hinweis: diese Angaben sind individuell und können für deinen Anwendungsfall abweichen.',
    title: '<span class="has-marker">1 Durchlauf</span> Kreativitätssteigerung entspricht',
  },
  usedBy: {
    logos: [
      {
        alt: 'Logo Strobel & Walter',
        src: 'storyliner/logos/logo-strobel-walter_miu1pb',
      },
      {
        alt: 'Logo Hyggins.pet',
        src: 'storyliner/logos/logo-hyggins_j7warc',
      },
      {
        alt: 'Logo Paceheads',
        src: 'storyliner/logos/logo-paceheads_tcdj0e',
      },
      {
        alt: 'Logo Petsahoi',
        src: 'storyliner/logos/logo-pets-ahoi_ipzbzr',
      },
    ],
    title: 'Du bist in kreativer Gesellschaft',
  },
  seo: {
    description:
      'Entfessele dein kreatives Potenzial. Wähle einen Plan, der zu dir und deinem Content-Projekt passt. Preise ansehen …',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Pläne und Preise - Storyliner',
  },
};
