/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// about
export default {
  brandStory:
    '<p>For thousands of years, we have communicated with each other in word, image, and writing. Many things have continuously changed: the medium, the place, or the way. But the disruptions of the past decades – and especially of the last ten years – have led to rapid changes in media consumption. Each of us consumes more and more messages in an ever shorter time.<p><p>This shift creates massive challenges for companies and organizations that need to develop content themselves: how content is produced has not changed - the creative process begins on a blank sheet of paper.</p><p>Where content is produced under time pressure, quality suffers. Poor quality leads to less attention and persuasiveness. Less persuasiveness leads to less trust. It is no secret that trust is the basis of our economic existence.</p><p>Behind <em>Storyliner</em> is an interdisciplinary team that combines creativity and technology. We believe in good stories and in the people who tell them. We are designers and developers, techies, copywriters, and data enthusiasts. Our mission is to empower every person and brand with technology to create compelling content.</p><blockquote>We believe that the future of artificial intelligence will support humans - fostering their creativity and enhancing their abilities. Therefore, we develop Storyliner.</blockquote>',
  hero: {
    title: 'We empower brands to create <span class="has-marker">compelling content</span>.',
  },
  jobs: {
    noJobs: 'We have currently filled all vacancies.',
    text: 'The Hamburg-based company Storyliner Labs is behind <em>Storyliner</em>. Based on years of experience in product management, development, marketing, and design, you can firsthand experience and shape every product development discipline. Our dedicated team offers flexible working hours and models, further education opportunities, and space for your ideas.',
    title:
      '<span class="has-marker">Help us</span> to enhance human creativity through technology.',
  },
  partners: {
    dividerBottom: 'divider-bottom-allports-2',
    dividerBottomDark: 'divider-bottom-tawnyport-1',
    logos: [
      {
        alt: 'Logo CONTENTshift',
        src: 'storyliner/logos/logo-contentshift_v1zxr7',
      },
      {
        alt: 'Logo KI Bundesverband',
        src: 'storyliner/logos/logo-ki-bundesverband_kvosfx',
      },
      {
        alt: 'Logo next media accelerator',
        src: 'storyliner/logos/logo-nma_cse7qo',
      },
      {
        alt: 'Logo AWS Activate',
        src: 'storyliner/logos/logo-aws-activate_k6cvnb',
      },
    ],
    title: 'Our partners',
  },
  seo: {
    description:
      'Behind Storyliner is an interdisciplinary team that combines creativity and technology. Learn more …',
    image: `${storylinerRootDomain}/img/meta/storyliner-team.jpg`,
    title: 'About us - Storyliner',
  },
  team: {
    image: {
      alt: 'Team Storyliner',
      src: 'storyliner/team/storyliner-team_h9vsjc',
    },
  },
};
