// import translations
// import community from './community';
import about from './about';
import blog from './blog';
import earlyAccessConfirmed from './early-access-confirmed';
import earlyAccessThankYou from './early-access-thank-you';
import features from './features';
import genres from './genres';
import helpCenter from './help-center';
import home from './home';
import howItWorks from './how-it-works';
import newsletterConfirmed from './newsletter-confirmed';
import newsletterThankYou from './newsletter-thank-you';
import notFound from './404';
import press from './press';
import pricing from './pricing';
import releaseNotes from './release-notes';
import releaseNote from './release-note.json';

// create translations obj
const translations = {
  'early-access-confirmed': earlyAccessConfirmed,
  'early-access-thank-you': earlyAccessThankYou,
  'help-center': helpCenter,
  'how-it-works': howItWorks,
  'newsletter-confirmed': newsletterConfirmed,
  'newsletter-thank-you': newsletterThankYou,
  'not-found': notFound,
  'release-note': releaseNote,
  'release-notes': releaseNotes,
  // community,
  about,
  blog,
  features,
  genres,
  home,
  press,
  pricing,
};

export default translations;
