/* eslint-disable react/jsx-props-no-spreading */

// import node_modules
import * as PropTypes from 'prop-types';
import * as React from 'react';
import Script from 'next/script';
import { enableES5 } from 'immer';

// import types
import { AppPropsWithLayout } from '../types';

// import components
import AppProviders from '../components/providers/AppProviders';
import GlobalHead from '../components/meta/GlobalHead';

// import fonts
import '@fontsource/poppins/600-italic.css';
import '@fontsource/poppins/600.css';
import '@fontsource/source-code-pro/400-italic.css';
import '@fontsource/source-code-pro/400.css';
import '@fontsource/source-sans-pro/400-italic.css';
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/700-italic.css';
import '@fontsource/source-sans-pro/700.css';

// import storyliner styles
import '@storylinerlabs/design/dist/design.css';

// https://fkhadra.github.io/react-toastify/introduction
import 'react-toastify/dist/ReactToastify.css';

// import website styles
import '../styles/index.css';

// enable immer
enableES5();

// App component
const StorylinerWebsite: React.FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  // render the page
  return (
    <>
      {/* adds meta tags */}
      <GlobalHead />

      {/* This enables dark mode */}
      <Script
        id="dark-mode-script"
        dangerouslySetInnerHTML={{
          __html: `
          (function() {
            if (localStorage && localStorage.theme && localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
              document.querySelector('html').classList.remove('light')
              document.querySelector('html').classList.add('dark')
            } else {
              document.querySelector('html').classList.remove('dark')
              document.querySelector('html').classList.add('light')
            }
          })();
        `,
        }}
      />

      {/* render app */}
      <AppProviders>{getLayout(<Component {...pageProps} />)}</AppProviders>
    </>
  );
};

StorylinerWebsite.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

StorylinerWebsite.displayName = 'StorylinerWebsite';

export default StorylinerWebsite;
