/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// newsletter thank you
export default {
  blog: {
    title:
      'Learn more about the <span class="has-marker">exciting</span> future of content creation',
  },
  hero: {
    title: 'Thank you for your subscription',
    intro:
      'Before we can give you access, you need to confirm your email address. To complete the registration, please click on the link in the email we have just sent to you. If you have any questions or concerns, please contact our support team: <a href="mailto:support@storylinerlabs.com">support@storylinerlabs.com</a>',
  },
  seo: {
    description:
      'Before we can give you access, you need to confirm your email address. To complete the registration, please click on the link in the email we have just sent to you.',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Thank you for your subscription - Storyliner',
  },
};
