/* eslint-disable import/no-anonymous-default-export */

// import types
import { RouteEnum } from '../../../types';

// import config
import { storylinerRootDomain } from '../../../../config';

// how it works
export default {
  buildingBlocks: {
    items: [
      {
        image: 'storyliner/svgs/storyliner-block_f3nobb',
        text: 'Blöcke sind wie einzelne Szenen, die zusammen eine Story erzählen. Du kannst Blöcke verwenden, um deine Idee zu einem Konzept zu entwickeln. Du kannst Passagen formatieren, Text arrangieren, Inhalte einfügen und den perfekten Workflow für deinen Content-Betrieb etablieren.',
        title: 'Block',
      },
      {
        image: 'storyliner/svgs/storyliner-storyline_weiv21',
        text: 'Eine Storyline definiert die erzählerische Struktur deiner Story. Mit Storyliner kannst du Blöcke in einer logischen Reihenfolge aneinanderreihen, um die gesamte Story leicht verständlich zu machen und sie effizienter für verschiedene Kommunikationskanäle anzupassen.',
        title: 'Storyline',
      },
      {
        image: 'storyliner/svgs/storyliner-document_let6u7',
        text: 'Aus der Storyline lässt sich ein Dokument ableiten, das den endgültigen Inhalt für einen bestimmten Anlass, Kommunikationskanal oder ein bestimmtes Inhaltsformat enthält. Auf diese Weise kannst du auf einfache Weise mehrere Dokumente aus einer Storyline erstellen.',
        title: 'Dokument',
      },
      {
        image: 'storyliner/svgs/storyliner-story_xzb6lt',
        text: 'Mit Storyliner erstellst du überzeugende Inhalte - oder anders ausgedrückt: Storys. Eine Story hält alles zusammen: das kreative Briefing, die Dokumente für jeden Kommunikationskanal, die Verantwortlichkeiten deines Teams, den redaktionellen Status und die Storyline.',
        title: 'Story',
      },
    ],
    title: 'Wie Storyliner dir beim Kreieren hilft',
  },
  creativeEnhancement: {
    callToAction: {
      href: RouteEnum.WEBSITE_PRICING_DE,
      label: 'Preise ansehen',
    },
    tabs: [
      {
        image: {
          alt: 'Textgenerierung',
          src: 'storyliner/svgs/storyliner-creative-enhancement-text-generation_kvs0xu',
        },
        isDisabled: false,
        label: 'Textgenerierung',
        text: 'Unser Sprachmodell generiert auf Basis deines kreativen Briefings Textvorschläge für Überschriften, Einleitungen etc., die du direkt als Inspiration übernehmen kannst.',
      },
      {
        image: {
          alt: 'Inhaltsanalyse',
          src: 'storyliner/svgs/storyliner-creative-enhancement-content-analytics_jvqzma',
        },
        isDisabled: false,
        label: 'Inhaltsanalyse',
        text: 'Während des Erstellungsprozesses wirst du durch NLP-Algorithmen unterstützt, die deine Rechtschreibung, Grammatik oder Sprachqualität überprüfen und verbessern. Die Analysen sind individuell und basieren auf deinen festgelegten Kommunikationsrichtlinien.',
      },
      {
        image: {
          alt: 'Maschinenübersetzung',
          src: '',
        },
        isDisabled: true,
        label: 'Maschinenübersetzung',
        text: 'Bald verfügbar…',
      },
    ],
    text: 'Storyliner basiert auf den weltweit fortschrittlichsten KI-Sprachmodellen und hilft dir, deine Kreativität zu steigern und gleichzeitig deine Qualität und Produktivität zu erhöhen.',
    title:
      'Steigere deinen <span class="has-marker">kreativen Output</span> mit der Hilfe von Künstlicher Intelligenz.',
  },
  enrich: {
    tabs: [
      {
        image: {
          alt: 'Strukturiere und verfasse deine Storys mit Leichtigkeit und unseren Konzeptblöcken.',
          src: 'storyliner/svgs/storyliner-enrich-concept-blocks_txptvv',
        },
        label: 'Konzept-Blöcke',
      },
      {
        image: {
          alt: 'Inspiration auf Knopfdruck - mit KI-generierten Texten oder professionellen Textvorlagen.',
          src: 'storyliner/svgs/storyliner-enrich-inspiration_prlt3b',
        },
        label: 'Inspiration',
      },
      {
        image: {
          alt: 'Speichere Inhaltsblöcke von überall - baue Storys Block für Block auf.',
          src: 'storyliner/svgs/storyliner-enrich-saved-blocks_ogulyz',
        },
        label: 'Gespeicherte Blöcke',
      },
      {
        image: {
          alt: 'Maximiere dein Kreationserlebnis durch die Verwendung von Einbettungen, Medienimporten und Inhaltsintegrationen.',
          src: 'storyliner/svgs/storyliner-enrich-partner-integrations_lqx3sw',
        },
        label: 'Partner-Integrationen',
      },
    ],
    title:
      '<span class="has-marker">Reichere</span> deine Storys mit intelligenten Blöcken, gespeicherten Inhalten und unseren Partnerintegrationen an.',
  },
  hero: {
    image: 'storyliner/website/storyliner-how-it-works-cover_uxgtox',
    title: 'Sei kein Sisyphus, sei ein <span class="has-marker">Storyliner</span>.',
    intro:
      "Jahrelang war das Erstellen von Inhalten eine Sisyphusarbeit. Nicht mehr – wir haben es geändert. Storyliner's frischer Ansatz verwandelt die Inhaltserstellung in etwas, das du gerne tust, statt endlosem hin- und herkopieren, mikro-managen von Tools oder einem dauerhaften Mangel an Ideen.",
  },
  seo: {
    description:
      'Wir glauben, dass das Erstellen von Inhalten einfach sein sollte, denn jeder verdient es, smarter zu arbeiten - nicht härter. Schau dir wie es funktioniert…',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Wie es funktioniert - Storyliner',
  },
  steps: {
    items: [
      {
        image: 'storyliner/website/storyliner-how-it-works-step-1-genre_xjtutc',
        text: 'Beginne mit einem Genre - einer vordefinierten professionellen Darstellungsform. Du kannst auch mit einer selbst-entwickelten Storyline oder von Grund auf neu beginnen.',
        title: '#1 – Wähle ein Genre',
      },
      {
        image: 'storyliner/website/storyliner-how-it-works-step-2-briefing_kgm74x',
        text: 'Im Briefing planst du deine Story. Du definierst dein Publikum, das Ziel und beschreibst das Konzept. Es ist die Grundlage für die maschinell generierte Inspiration.',
        title: '#2 – Fülle das Briefing aus',
      },
      {
        image: 'storyliner/website/storyliner-how-it-works-step-3-storyline_q4tdlw',
        text: 'Die Storyline stellt die inhaltliche Grundlage der Story dar. Du entwickelst sie einmal und kannst sie dann leicht für die einzelnen Kommunikationskanäle und Anlässe anpassen.',
        title: '#3 – Komponiere deine Storyline',
      },
      {
        image: 'storyliner/website/storyliner-how-it-works-step-4-document_hmhbtf',
        text: 'Stimme deine Inhalte für jeden Kommunikationskanal ab. Hole dir Maschinen-generierte Inspiration, Beispiele und Inhaltsanalysen sowie Feedback von deinem Content-Team.',
        title: '#4 – Vervollständige die Dokumente',
      },
      {
        image: 'storyliner/website/storyliner-how-it-works-step-5-download_xnivsg',
        text: 'Veröffentliche die fertige Story auf der Plattform deiner Wahl. Du kannst jedes Dokument einzeln herunterladen oder es direkt über die Zapier-Integration veröffentlichen.',
        title: '#5 – Veröffentliche deine Story',
      },
    ],
    title:
      'Erstelle ein Konzept <span class="has-marker">in Minuten</span>, veröffentliche hochwertige Inhalte.',
  },
  testimonial: {
    bgColor: 'anakiwa',
    color: 'black',
    colorDark: 'white',
    image: 'storyliner/testimonials/testimonial-dominic-strobel_vbg9le',
    jobTitle: 'Geschäftsführer, Strobel & Walter GmbH',
    name: 'Dominic Strobel',
    quote:
      '„Mit Storyliner haben wir es geschafft, Struktur in unsere Redaktionsabläufe für Instagram zu bekommen. Basierend auf festgelegten Storylines haben wir wiederkehrende Formatreihen entwickelt, die uns viel Zeit bei der Content-Produktion sparen.“',
    snippet: 'snippet-anakiwa-1',
    snippetDark: 'snippet-tawnyport-1',
  },
};
