/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// newsletter thank you
export default {
  blog: {
    title:
      'Erfahre mehr über die <span class="has-marker">spannende</span> Zukunft der Inhaltserstellung',
  },
  hero: {
    intro:
      'Bevor wir du zum Newsletter angemeldet wirst, musst du deine E-Mail-Adresse bestätigen. Um die Registrierung abzuschließen, klicke bitte auf den Link in der E-Mail, die wir dir gerade gesendet haben. Bei Fragen oder Unklarheiten, melde dich bitte bei unserem Support-Team: <a href="mailto:support@storylinerlabs.com">support@storylinerlabs.com</a>',
    title: 'Danke für deine Anmeldung',
  },
  seo: {
    description:
      'Bevor wir du zum Newsletter angemeldet wirst, musst du deine E-Mail-Adresse bestätigen. Um die Registrierung abzuschließen, klicke bitte auf den Link in der E-Mail, die wir dir gerade gesendet haben.',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Danke für deine Anmeldung - Storyliner',
  },
};
