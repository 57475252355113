/* eslint-disable import/no-anonymous-default-export */

// import types
import { RouteEnum } from '../../../types';

// import config
import { storylinerRootDomain } from '../../../../config';

// home
export default {
  audiences: {
    items: [
      {
        description:
          'Manage your communication policies in one convenient place. Automate your content creation with defined formats and standardized creative briefings.',
        icon: 'ri-pie-chart-box-line',
        name: 'Content Strategists',
      },
      {
        description:
          'No more ping-ponging between documents, approval processes, and niche workflow apps: With Storyliner, you design content in a centralized, flexible creation environment.',
        icon: 'ri-pencil-ruler-2-line',
        name: 'Creatives',
      },
      {
        description:
          'Translate your knowledge into compelling content using generated narrative structures, professional genres, and inspiring examples. Develop content for all your communication occasions in no time at all.',
        icon: 'ri-lightbulb-line',
        name: 'Entrepreneurs',
      },
      {
        description:
          'Content creation becomes better when working in a team. Collaborate with others on concepts, evaluate the content or get real-time feedback on headlines or other content components.',
        icon: 'ri-group-2-line',
        name: 'Content teams',
      },
      // {
      //   description:
      //     'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
      //   icon: 'ri-newspaper-line',
      //   name: 'PR specialists',
      // },
      // {
      //   description:
      //     'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
      //   icon: 'ri-search-eye-line',
      //   name: 'Journalists',
      // },
      {
        description:
          'Develop your stories once and adapt them for different communication channels. Optimize and publish your content directly on social media platforms with just a few clicks.',
        icon: 'ri-instagram-line',
        name: 'Social Media Managers',
      },
      {
        description:
          'No more monotonous texts. Effortlessly draft new blog articles using generated genres and custom formats. Optimize your posts for search engines with tips and content analysis.',
        icon: 'ri-draft-line',
        name: 'Copywriters',
      },
      // {
      //   description:
      //     'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
      //   icon: 'ri-mail-send-line',
      //   name: 'Agencies',
      // },
    ],
    callToAction: {
      label: 'Explore all features',
      href: RouteEnum.WEBSITE_FEATURES_EN,
    },
    title: 'For all who have something to say',
  },
  benefits: {
    items: [
      {
        description:
          "Organize your creative chaos, structure ideas, or save notes using Storyliner's smart content blocks. With professional block compositions – the Storyliner genres – you turn loose content into compelling stories in no time.",
        headline: 'Compose content faster with smart content blocks',
        image: 'storyliner/website/storyliner-benefit-smart-blocks_qp8eoc',
      },
      {
        description:
          'Get generated texts, inspiration, automated translations, and recommendations based on your strategy. Improve your story along the content creation process: for every communication occasion and every communication channel.',
        headline: 'Creative enhancement, from idea to publication.',
        image: 'storyliner/website/storyliner-benefit-creative-enhancement_na0kiv',
      },
      {
        description:
          'No more ping-ponging between documents and niche workflow apps to get things done: Storyliner brings all your content, data, and team members together in a creative environment to boost your creative output.',
        headline: 'Content creation that brings data and teams together',
        image: 'storyliner/website/storyliner-benefit-collaboration_gc5qkz',
      },
    ],
    callToAction: {
      label: 'See how it works',
      href: RouteEnum.WEBSITE_HOWITWORKS_EN,
    },
    title: '<span class="has-marker">One place</span> for creative excellence',
  },
  blog: {
    title:
      'Learn more about the <span class="has-marker">exciting</span> future of content creation',
  },
  genres: {
    callToAction: {
      label: 'Explore genres',
      href: RouteEnum.WEBSITE_GENRES_EN,
    },
    dividerBottom: 'divider-bottom-desertstorm-1',
    dividerBottomDark: 'divider-bottom-tuatuara-1',
    intro:
      'Get inspired by our generated professional presentation forms, the Storyliner genres, and automate your content creation.',
    title: 'Your idea, various storylines.',
  },
  hero: {
    intro:
      'With Storyliner, <strong>creatives and content teams</strong> conceive communication strategically on <strong>one platform</strong>. Through smart content blocks, inspiration, and constant feedback on your quality, you <strong>create concepts in minutes</strong>.',
    title:
      '<span class="has-marker">Compelling</span> content. <br />Enhanced by AI, crafted together.',
  },
  press: {
    dividerBottom: 'divider-bottom-tuatuara-1',
    logos: [
      {
        alt: 'Logo Börsenblatt',
        src: 'storyliner/logos/logo-boersenblatt_j0bdwu',
      },
      {
        alt: 'Logo Hamburg Startups',
        src: 'storyliner/logos/logo-hamburg-startups_cf8av0',
      },
      {
        alt: 'Logo Werben und Verkaufen',
        src: 'storyliner/logos/logo-wundv_e4fggp',
      },
      {
        alt: 'Logo Junge Verlagsmenschen',
        src: 'storyliner/logos/logo-junge-verlagsmenschen_ez0jsy',
      },
    ],
    title: 'Featured in',
  },
  productPreview: {
    videoPoster: 'storyliner/website/storyliner-product-demo_fd5vl2',
    videoUrl: 'https://www.youtube-nocookie.com/embed/BbAbQmX8uag',
  },
  seo: {
    description:
      'With smart blocks, inspiration, and content analysis, you create compelling content in minutes. Sign up now!',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Storyliner – the AI-enhanced creative platform',
  },
  testimonial: {
    bgColor: 'allports',
    color: 'white',
    colorDark: 'white',
    image: 'storyliner/testimonials/testimonial-daniela-keller_yxwx3t',
    jobTitle: 'Statistic Expert & Blogger, statistik-und-beratung.de',
    name: 'Daniela Keller',
    quote:
      '„With the many format ideas and suggestions from Storyliner, I get more variety in my articles.“',
    snippet: 'snippet-anakiwa-1',
    snippetDark: 'snippet-tawnyport-1',
  },
};
