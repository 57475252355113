/* eslint-disable import/no-anonymous-default-export */

// import config
import { storylinerRootDomain } from '../../../../config';

// blog
export default {
  hero: {
    title: 'Your <span class="has-marker">creative</span> upgrade',
    intro: 'We analyze, research, and shape the future of content creation.',
  },
  nextPage: {
    href: '/blog/page/2',
    label: 'Next page',
  },
  seo: {
    description:
      'Analyzing, researching and shaping the future of content creation. Read the Storyliner blog…',
    image: `${storylinerRootDomain}/img/screensavers/screensaver-break-creative-barriers-storyliner.jpg`,
    title: 'Blog - Storyliner',
  },
};
