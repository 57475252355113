// Style enums

// define types
export type StorylinerIconAlignment = 'left' | 'center' | 'right';

export enum StorylinerIconAlignmentEnum {
  left = 'left',
  center = 'center',
  right = 'right',
}

export type PriceBoxColor = 'anakiwa' | 'desertStorm' | 'yellow';

export enum PriceBoxColorEnum {
  anakiwa = 'anakiwa',
  desertStorm = 'desertStorm',
  yellow = 'yellow',
}

export type Currency = 'dollar' | 'euro';

export enum CurrencyEnum {
  dollar = 'dollar',
  euro = 'euro',
}

export type BillingCycle = 'annually' | 'monthly';

export enum BillingCycleEnum {
  annually = 'annually',
  monthly = 'monthly',
}

export type QuoteColor = 'white' | 'black';

export enum QuoteColorEnum {
  white = 'white',
  black = 'black',
}

export type SectionColor =
  | 'allports'
  | 'anakiwa'
  | 'desertStorm'
  | 'energyYellow'
  | 'java'
  | 'tuatara'
  | 'white';

export enum SectionColorEnum {
  allports = 'allports',
  anakiwa = 'anakiwa',
  desertStorm = 'desertStorm',
  energyYellow = 'energyYellow',
  java = 'java',
  tuatara = 'tuatara',
  white = 'white',
}

export type SectionAlignment = 'bottom' | 'middle' | 'top';

export enum SectionAlignmentEnum {
  bottom = 'bottom',
  middle = 'middle',
  top = 'top',
}

export type SectionFlexDirection = 'column' | 'row' | 'row-reverse' | 'column-reverse';

export enum SectionFlexDirectionEnum {
  column = 'column',
  columnReverse = 'column-reverse',
  row = 'row',
  rowReverse = 'row-reverse',
}
